import React from 'react';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import moment from 'moment';
import { ChartViewEnums, renderChartValueFormat } from './constants';

// Register chart.js components
ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Title, Tooltip, Legend);

const LineCard = ({ lineChartDates, activeChart, chartView }) => {
    const dataValues = lineChartDates.map((item) => item.Current);

    const data = {
        labels: lineChartDates?.map((i) => i?.date),
        datasets: [
            {
                // label: chartLabel || renderChartValueFormat(activeChart?.valueFormat),
                data: dataValues,
                fill: false,
                borderColor: '#0C66E4',
                tension: 0.1,
                pointBackgroundColor: '#fff',
                pointBorderColor: '#0C66E4',
                pointRadius: 5,
                pointHoverRadius: 6,
                pointBorderWidth: 2,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'top',
                labels: {
                    boxWidth: 0,
                    usePointStyle: false,
                    generateLabels: () => [],
                },
                onClick: () => null,
            },
            tooltip: {
                enabled: false,
                external: (context) => {
                    let tooltipEl = document.getElementById('chartjs-tooltip');
                    if (!tooltipEl) {
                        tooltipEl = document.createElement('div');
                        tooltipEl.id = 'chartjs-tooltip';
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.background = 'rgba(255, 255, 255)';
                        tooltipEl.style.border = '1px solid white';
                        tooltipEl.style.borderRadius = '6px';
                        tooltipEl.style.width = 'auto';
                        tooltipEl.style.height = 'auto';
                        tooltipEl.style.pointerEvents = 'none';
                        tooltipEl.style.transition = 'all 0.2s ease';
                        tooltipEl.style.opacity = '0';
                        tooltipEl.style.zIndex = '1000';
                        tooltipEl.style.boxShadow = '0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)';
                        document.body.appendChild(tooltipEl);
                    }

                    const tooltipModel = context.tooltip;
                    if (!tooltipModel || tooltipModel.opacity === 0) {
                        tooltipEl.style.opacity = '0';
                        return;
                    }

                    const { chart } = context;
                    const canvasRect = chart.canvas.getBoundingClientRect();

                    // Adjust tooltip position (add 50px closer to the pointer)
                    tooltipEl.style.left = `${canvasRect.left + tooltipModel.caretX - 50}px`; // Move left by 50px
                    tooltipEl.style.top = `${canvasRect.top + tooltipModel.caretY - tooltipEl.offsetHeight / 2}px`;
                    tooltipEl.style.opacity = '1';
                    // Set content
                    if (tooltipModel.title && tooltipModel.body) {
                        const title = tooltipModel.title[0];
                        const body = tooltipModel.body[0]?.lines[0];

                        tooltipEl.innerHTML = `
         <div style="font-size: 16px; color: gray;  font-family: Open Sans, sans-serif">
    <div style="border-bottom: 1px solid lightgray; padding: 12px 8px">
        <strong style="margin: 8px; font-weight: 500">${moment(title).format(chartView === ChartViewEnums.DAILY ? 'MM/DD/YY' : 'MMM YYYY')}</strong><br />
    </div>

    <div style="padding:12px 8px; display: flex; align-items: center;">
        <div style="margin-right: 30px; margin-left: 10px; border: 2px solid white; width: 12px; height: 12px; border-radius: 50%; background: #0C66E4; box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)"></div>
        <span style="color: black">${body ? `${renderChartValueFormat(activeChart?.valueFormat)}${body}` : 0}</span>
    </div>
</div>
        `;
                    }
                },
            },

        },
        scales: {
            x: {
                ticks: {
                    color: '#858D9D',
                    fontFamily: 'Open Sans, sans-serif',
                    fontWeight: 500,
                    callback: (value, index, ticks) => {
                        return moment(lineChartDates[index]?.date).format(
                            chartView === ChartViewEnums.DAILY ? 'MM/DD/YY' : 'MMM YYYY',
                        ) || '';
                    },
                },
                grid: {
                    color: '#F0F1F3',
                },
                border: {
                    color: '#F0F1F3'
                }
            },
            y: {
                ticks: {
                    color: '#858D9D',
                    fontFamily: 'Open Sans, sans-serif',
                    callback: (value) => {
                        return `${renderChartValueFormat(activeChart?.valueFormat)}${value}`;
                    },
                },
                title: {
                    display: true,
                },
                beginAtZero: true,
                grid: {
                    color: '#F0F1F3',
                },
                border: {
                    color: '#F0F1F3'
                }
            },
        },
    };


    return (
        <div style={{ width: '100%', height: '400px', position: 'relative' }}>
            <Line data={data} options={options} />
        </div>
    );
};

export default LineCard;
