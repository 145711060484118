import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { serviceSingleStyles } from './styles';
import {
    AddButton,
    AddNotes,
    MinLoader, NameAndAvatar, DetailInfoCard, UploadModal, UserInputsDropdown, SearchHistory, TagBox, AddButtonLight,
} from 'components';
import {
    Colors,
    FindLoad,
    Images,
    makeCapitalize,
    PermissionList, renderStaffName,
    RolePermission, staffStatuses,
    useModal,
} from 'utils';
import { CredentialModal } from 'fragments';
import { adminActions } from 'store';
import { staffStatus } from './constants';
import { TimeSheetModalSelector } from '../../modals';
import { PaycodeModal } from './modals';

// const IS_CLINICIAN = 'IS_CLINICIAN';
const STATUS_ACTION_TYPE = 'CHANGE_ADMIN_STATUS';
export const StaffItemHeader = ({}) => {
    const { adminInfoById } = useSelector((state) => ({
        adminInfoById: state.admins.adminInfoById,
    }));
    const activeEmployment = adminInfoById?.employment;
    const history = useHistory();
    const info = history?.location?.state;
    const classes = serviceSingleStyles();
    const dispatch = useDispatch();
    // const [switchBoolean, setSwitchBoolean] = useState(false);
    const loadReinvite = FindLoad('REINVITE_ADMIN');
    const { open } = useModal();

    // useEffect(() => {
    //     if (adminInfoById?.id) {
    //         setSwitchBoolean(adminInfoById?.clinical);
    //     }
    // }, [adminInfoById]);

    const reinvite = () => {
        dispatch(adminActions.reinviteAdmin(adminInfoById?.id));
    };

    const handleChangeStatus = (params) => {
        dispatch(adminActions.changeAdminStatus(adminInfoById.id, params));
    };

    return (
        <Fragment>
            <div className="tabs-wrapper">
                <div className="full-width">
                    <div className="space-between-flex-start">
                        <NameAndAvatar
                            src={adminInfoById?.avatar?.url}
                            // name={makeCapitalize(`${adminInfoById?.firstName} ${adminInfoById?.middleName ? adminInfoById?.middleName : ''} ${adminInfoById?.lastName}`)}
                            name={renderStaffName(adminInfoById)}
                        />

                        <div className="flex-align-start" style={{ gap: 16 }}>
                            {RolePermission([PermissionList.STAFF_STATUS?.code]) &&
                                <UserInputsDropdown
                                    dropdownOptions={staffStatuses}
                                    actionType={STATUS_ACTION_TYPE}
                                    selected={adminInfoById?.status}
                                    onPass={handleChangeStatus}
                                    type={'Staff'}
                                />
                            }
                            {
                                (!info?.activeTab || info?.activeTab === 'General') && adminInfoById?.authStatus !== staffStatus?.ACTIVE &&
                                RolePermission([PermissionList.STAFF_CREATE.code]) &&
                                <button className={classes.reinvite} onClick={reinvite}>
                                    {loadReinvite?.length ?
                                        <MinLoader margin={'0'} color={Colors.TextWhite} position={'relative'} />
                                        :
                                        <>
                                            <img src={Images.reinvite} alt="icon" />
                                            <p>Reinvite</p>
                                        </>
                                    }
                                </button>
                            }

                            {info?.activeTab === 'Paycodes' && RolePermission([PermissionList.STAFF_PAYCODE_MANAGE?.code]) &&
                                <AddButton text={'Add Paycode'} handleClick={() => open(<PaycodeModal />)} />
                            }

                            {
                                info?.activeTab === 'Timesheet' && RolePermission([PermissionList.STAFF_TIMESHEET_CREATE?.code]) ? (
                                    <AddButton text="Add Timesheet" handleClick={() => open(
                                        <TimeSheetModalSelector fromStaff={true} />,
                                    )} />


                                ) : info?.activeTab === 'Credentials' && RolePermission([PermissionList.STAFF_CREDENTIAL_MANAGE?.code]) ? (
                                        <AddButton
                                            text="Add Credential"
                                            handleClick={() => open(<CredentialModal />)}
                                        />
                                    ) :
                                    info?.activeTab === 'Notes' && RolePermission([PermissionList.STAFF_NOTE_MANAGE?.code]) ? (
                                            <AddButton text="Add Note" handleClick={() => open(
                                                <AddNotes
                                                    id={adminInfoById?.id}
                                                    model="Staff"
                                                />,
                                            )} />
                                        ) :
                                        info?.activeTab === 'History' ? (
                                                <SearchHistory />
                                            ) :
                                            info?.activeTab === 'Files' && RolePermission([PermissionList.STAFF_FILE_MANAGE?.code]) ?
                                                <AddButton
                                                    Icon={true}
                                                    customIcon={Images.uploadCloud}
                                                    text={'Upload Document'}
                                                    handleClick={() => open(
                                                        <UploadModal
                                                            currentId={adminInfoById?.id}
                                                            onResource={'staff'}
                                                        />,
                                                    )}
                                                />
                                                : null
                            }
                        </div>
                    </div>

                    <TagBox tags={adminInfoById?.tags} noTool={true} />

                    <div className={classes.staffGeneralWrapper}>
                        {activeEmployment?.title?.name &&
                            <DetailInfoCard image={Images.staffPosition}
                                            title={activeEmployment?.title?.name} />
                        }
                        {adminInfoById?.email &&
                            <DetailInfoCard image={Images.sms} title={adminInfoById?.email}
                                            type={'email'} />
                        }
                        {adminInfoById?.phone &&
                            <DetailInfoCard image={Images.callCalling} title={adminInfoById?.phone}
                                            type={'phone'} />
                        }
                    </div>
                </div>
            </div>
        </Fragment>
    );
};
