import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Popover from '@mui/material/Popover';
import { httpRequestsOnSuccessActions, tagActions } from 'store';
import { FindLoad, FindSuccess, Images, useModal, useWidth } from 'utils';
import { MinLoader } from '../loader';
import { renderColor, tagColors } from './colors';
import { CustomDelete } from '../modal';

const CREATE_ACTION_TYPE = 'CREATE_TAG';
const EDIT_TAG_ACTION_TYPE = 'EDIT_TAG';
const DELETE_ACTION_TYPE = 'DELETE_TAG';
const REMOVE_ACTION_TYPE = 'REMOVE_TAG_FROM_MEMBER';
const ADD_TAG_TO_MEMBER = 'ADD_TAG_TO_MEMBER';

export const TagComponent = ({ userInfo, module, requestParams }) => {
    const { tagList } = useSelector((state) => ({
        tagList: state.tags.tagList,
    }));
    const [anchorEl, setAnchorEl] = useState(null);
    const openAnchor = Boolean(anchorEl);
    const id = openAnchor ? 'simple-popover' : undefined;
    const dispatch = useDispatch();
    const createLoad = FindLoad(CREATE_ACTION_TYPE);
    const createSuccess = FindSuccess(CREATE_ACTION_TYPE);
    const deleteLoad = FindLoad(DELETE_ACTION_TYPE);
    const deleteSuccess = FindSuccess(DELETE_ACTION_TYPE);
    const removeFromList = FindLoad(REMOVE_ACTION_TYPE);
    const removeSuccess = FindSuccess(REMOVE_ACTION_TYPE);
    const editTagLoader = FindLoad(EDIT_TAG_ACTION_TYPE);
    const editTagSuccess = FindSuccess(EDIT_TAG_ACTION_TYPE);
    const addTagLoader = FindLoad(ADD_TAG_TO_MEMBER);
    const addTagSuccess = FindSuccess(ADD_TAG_TO_MEMBER);
    const [newTag, setNewTag] = useState('');
    const [editTag, setEditTag] = useState('');
    const [selectedId, setSelectedId] = useState('');
    const [filteredList, setFilteredList] = useState([]);
    const checkExistTag = tagList?.find((i) => i?.name === newTag);
    const { open } = useModal();
    const width = useWidth();
    const inputRef = useRef(null);
    const [randomColor, setRandomColor] = useState();

    useEffect(() => {
        setRandomColor(Math.floor(Math.random() * tagColors.length));
    }, [openAnchor]);

    useEffect(() => {
        if (deleteSuccess) {
            setEditTag('');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(DELETE_ACTION_TYPE));
        }
        if (removeSuccess) {
            setSelectedId('');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(REMOVE_ACTION_TYPE));
        }
        if (createSuccess) {
            setNewTag('');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_ACTION_TYPE));
        }
        if (editTagSuccess) {
            setSelectedId('');
            setEditTag('');
            setNewTag('');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(EDIT_TAG_ACTION_TYPE));
        }
        if (addTagSuccess) {
            setEditTag('');
            setNewTag('');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ADD_TAG_TO_MEMBER));
        }
    }, [deleteSuccess, removeSuccess, createSuccess, editTagSuccess, addTagSuccess]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setEditTag(null);
        setNewTag(null);
    };

    const createTag = () => {
        const tag = {
            'name': newTag,
            'color': tagColors[randomColor]?.color,
            'module': module,
        };
        dispatch(tagActions.createTag(tag, { module: module }));
    };

    useEffect(() => {
        const userTags = userInfo?.tags?.length ? userInfo?.tags : []
        const newList = tagList?.filter(function(array_el) {
            return userTags?.filter(function(anotherOne_el) {
                return anotherOne_el?._id === array_el?.id;
            }).length === 0;
        });
        setFilteredList(newList);
    }, [tagList, userInfo, openAnchor]);

    const handleSelectTag = (item) => {
        setSelectedId(item?.id);
        if(userInfo?.tags?.length < 5) {
            dispatch(tagActions.addTagToMember(userInfo?.id, item?.id, requestParams, module));
        }
    };

    const handleEditTag = (event, tag) => {
        setEditTag(tag);
        event.stopPropagation();
        event.preventDefault();
    };

    const chengTagColor = (i) => {
        setEditTag({
            color: i,
            id: editTag?.id || editTag?._id,
            name: editTag?.name,
            module: module,
        });
    };

    const handleChangeTagName = (e) => {
        setEditTag({
            color: editTag?.color,
            id: editTag?.id || editTag?._id,
            name: e?.target?.value,
            module: module,
        });
    };

    const handleDelete = (item) => {
        setAnchorEl(null);
        open(
            <CustomDelete
                info="Delete this tag?"
                text="Are you sure you want to delete this tag?"
                handleDel={() => dispatch(tagActions.deleteTag(item?._id || item?.id, module, requestParams))}
                handleClose={close}
                actionType={DELETE_ACTION_TYPE}
                body={
                    <div className="deleting-client-info-box" style={{ alignItems: 'flex-start' }}>
                        <img style={{ marginTop: '3px' }} src={Images.tagUser} alt="icon" />
                        <p style={{ fontWeight: 600, fontSize: 14 }}>{editTag?.name}</p>
                    </div>
                }
            />,
        );
    };

    const handleRemove = (tag) => {
        setSelectedId(tag?._id);
        dispatch(tagActions.removeTagFromMember(userInfo?.id, tag?._id, requestParams, module));
    };

    useEffect(() => {
        const defaultList = tagList?.filter(function(array_el) {
            return userInfo?.tags?.filter(function(anotherOne_el) {
                return anotherOne_el._id === array_el.id;
            }).length === 0;
        });
        const newList = defaultList?.filter((el) => el?.name?.toString().toLowerCase().indexOf(newTag?.toString().toLowerCase()) > -1);
        setFilteredList(newList);
    }, [newTag]);

    const handleSetNewTag = (e) => {
        setNewTag(e.target.value);
    };

    const handleDone = () => {
        if (editTag) {
            if (editTag?.name) {
                dispatch(tagActions.editTag(editTag, { module: module }, requestParams));
            } else {
                inputRef?.current?.focus();
            }
        } else {
            handleClose();
        }
    };

    return (
        <>
            <div className="table-tags-wrapper text-style"
                 onClick={width <= 768 ? (e) => handleClick(e) : () => {
                 }}
            >
                <div className="flex text-style">
                    <div className="desktop-page-view">
                        <button aria-describedby={id} onClick={handleClick} className="add-tag-button">
                            <img src={Images.addCircleBlue} alt="Plus Green" />
                        </button>
                    </div>

                    {userInfo?.tags?.length ? userInfo?.tags?.map((i, j) => (j <= 0 &&
                            <div key={j}
                                 className="table-tag-chip"
                                 style={{
                                     background: renderColor(i?.color).background,
                                     color: renderColor(i?.color).color,
                                 }}
                            >
                                {i?.name}
                            </div>
                        ))
                        :
                        <p style={{ whiteSpace: 'nowrap' }}>Not Set</p>

                    }

                    {userInfo?.tags?.length > 1 ?
                        <button aria-describedby={id} onClick={handleClick} className="table-tag-count-box">
                            +{userInfo?.tags?.length - 1}
                        </button>
                        : ''}
                </div>
            </div>

            <Popover
                id={id}
                open={openAnchor}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <div className="tag-fragment-wrapper">
                    <div className="tag-fragment-header-box">
                        <p>{
                            newTag ? 'Create Tag' :
                                editTag ? `Edit ${editTag?.name}` :
                                    'Tags'
                        }
                        </p>
                        <button onClick={handleDone}>
                            {editTagLoader?.length ?
                                <MinLoader color={'#0C66E4'} height={'16px'} width={'16px'} position={'relative'}
                                           margin={'0'} />
                                :
                                'Done'
                            }
                        </button>
                    </div>
                    {editTag ?
                        <div className="edit-tag-wrapper">
                            <div className="edit-tag-input-wrapper">
                                <div className="flex-align-center full-width" style={{ gap: 8 }}>
                                    <div className="tag-color-box">
                                        <div style={{ background: renderColor(editTag?.color).color }} />
                                    </div>
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        style={{ color: renderColor(editTag?.color).color }}
                                        className="edit-tag-input"
                                        value={editTag?.name}
                                        onChange={handleChangeTagName}
                                        maxLength={15}
                                    />
                                    <p className="tag-limit-count">{`${editTag?.name?.length}/15`}</p>
                                </div>
                            </div>

                            <button onClick={() => handleDelete(editTag)} className="delete-tag-wrapper">
                                {deleteLoad?.length ?
                                    <MinLoader color={'#222222CC'} />
                                    :
                                    <>
                                        <img src={Images.deleteTag} alt="delete" />
                                        <p>Delete tag</p>
                                    </>
                                }
                            </button>
                            <p className="choose-tag-color">Choose tag color</p>
                            <div className="color-cards-wrapper">
                                {tagColors?.map((i, j) => (
                                    <button
                                        onClick={() => chengTagColor(i?.background)} key={j}
                                        className={editTag?.color === i?.background ? 'current-color-card' : 'color-card'}
                                    >
                                        <div style={{ background: i?.color }} />
                                    </button>
                                ))}
                            </div>
                        </div>
                        :
                        <>
                            <div className="tag-fragment-header">
                                <div className="space-between" style={{ padding: '0 16px 0 0' }}>
                                    <input
                                        placeholder="Add tags"
                                        className="add-tag-input"
                                        type="text"
                                        onChange={handleSetNewTag}
                                        value={newTag}
                                        maxLength={15}
                                    />
                                    {newTag &&
                                        <p className="tag-limit-count">
                                            {`${newTag ? newTag?.length : 0}/15`}
                                        </p>
                                    }
                                    {newTag &&
                                        <button
                                            onClick={() => setNewTag('')}
                                            className="remove-tag-input-value"
                                        >
                                            <img src={Images.remove} alt="icon" />
                                        </button>
                                    }
                                </div>

                                <div className="user-tag-chip-wrapper">
                                    {userInfo?.tags?.map((i, j) => (
                                        <button
                                            key={j}
                                            className="user-tag-chip"
                                            style={{
                                                background: renderColor(i?.color).background,
                                                color: renderColor(i?.color).color,
                                            }}
                                            onClick={() => setEditTag(i)}
                                        >
                                            <p>{i?.name}</p>
                                            {removeFromList?.length && selectedId === i?._id ?
                                                <MinLoader
                                                    color={renderColor(i?.color).color}
                                                    height={'12px'}
                                                    width={'12px'}
                                                    position={'relative'}
                                                    margin={'0'}
                                                />
                                                :
                                                <img onClick={(e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                    handleRemove(i);
                                                }} src={Images.closeChip} alt="remove-icon" />
                                            }
                                        </button>
                                    ))
                                    }
                                </div>
                            </div>

                            <div className="tag-fragment-body">
                                <p className="select-option">Select an option or create one</p>
                                <div className="tag-items-wrapper">
                                    {filteredList?.length ?
                                        filteredList?.map((i, j) => (
                                            <div onClick={() => handleSelectTag(i)} key={j}
                                                 className="created-tags-wrapper">
                                                <div className="created-tags"
                                                     style={{
                                                         background: renderColor(i?.color).background,
                                                         color: renderColor(i?.color).color,
                                                     }}
                                                >
                                                    {i?.name}
                                                </div>
                                                <button onClick={(e) => handleEditTag(e, i)}
                                                        className="tag-edit-button">
                                                    {selectedId === i?.id && addTagLoader ?
                                                        <MinLoader color="#767676" />
                                                        :
                                                        <img src={Images.moreGray} alt="icon" />
                                                    }
                                                </button>
                                            </div>
                                        ))
                                        : ''
                                    }
                                </div>

                                {newTag && !checkExistTag &&
                                    <button
                                        style={{
                                            background: tagColors[randomColor].background,
                                            color: tagColors[randomColor].color,
                                            border: `1px solid ${tagColors[randomColor].color}`,
                                        }}
                                        // border: 1px solid #0C66E3;
                                        // background: #F5F9FE;

                                        onClick={createTag} className="create-tag-button">
                                        {createLoad?.length ?
                                            <MinLoader color="#D86FC4" />
                                            :
                                            <>
                                                <p>Create</p>
                                                <div className="create-tag-text"> {newTag} </div>
                                            </>
                                        }
                                    </button>
                                }
                            </div>
                        </>
                    }
                </div>
            </Popover>
        </>
    );
};