import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSecondaryModal } from 'utils';
import { httpRequestsOnSuccessActions } from 'store';
import { Loader } from '../../../../components';

const ACTION_TYPE = 'SEND_FORM';

export const SupportModal = () => {
    const formContainerRef = useRef(null);
    const { closeSecondary } = useSecondaryModal();
    const dispatch = useDispatch();
    const [loader, setLoader] = useState(true);


    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//js.hsforms.net/forms/embed/v2.js';
        script.type = 'text/javascript';
        script.charset = 'utf-8';

        script.onload = () => {
            if (window.hbspt) {
                window.hbspt.forms.create({
                    portalId: '48035702',
                    formId: '3cb43c52-1918-4d62-aa25-933b4fdc748d',
                    target: `#${formContainerRef.current.id}`,
                    onFormSubmit: () => {
                        closeSecondary();
                        dispatch(httpRequestsOnSuccessActions.appendSuccess(ACTION_TYPE));
                    },
                });
            }
        };

        document.body.appendChild(script);

        const interval = setInterval(() => {
            const iframe = document.querySelector('#hubspot-form-container iframe');
            if (iframe) {
                const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
                const submitButton = iframeDoc.querySelector('input[type="submit"]');
                if (submitButton) {
                    submitButton.style.backgroundColor = '#007bff';
                    submitButton.style.color = '#fff';
                    submitButton.style.width = '100%';
                    submitButton.style.height = '48px';
                    submitButton.style.padding = '10px 20px';
                    submitButton.style.border = 'none';
                    submitButton.style.borderRadius = '4px';
                    submitButton.style.fontSize = '16px';
                    submitButton.style.cursor = 'pointer';
                    clearInterval(interval);
                }
            }
        }, 0);

        return () => {
            document.body.removeChild(script);
        };
    }, []);


    return (
        <div style={{ width: 400, height: 350 }}>
            <div id="hubspot-form-container" ref={formContainerRef}></div>
        </div>
    );
};
