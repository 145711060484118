import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { Calendar } from 'react-date-range';
import moment from 'moment';
import { DateInput } from 'rsuite';
import { enGB } from 'date-fns/locale';
import { Images, newDateWithoutUtc } from 'utils';
import { Popper } from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { ErrMessage } from '../../messages';

export const MonthCalendarForm = ({ label, type, control, name, rules, max, min, disabled }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const customLocale = {
        ...enGB,
        options: {
            ...enGB.options,
            weekStartsOn: 1,
        },
    };

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const getNestedError = (obj, path) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    };

    const isValidDate = (dateString) => {
        const date = new Date(dateString);
        return !isNaN(date.getTime());
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field, formState: { errors } }) => {
                const error = getNestedError(errors, name);
                return (
                    <>
                        <div style={{ width: '100%' }}>
                            {label &&
                                <p
                                    style={
                                        disabled ? { color: 'rgba(0, 0, 0, 0.26)' } :
                                            type === 'filterInput' ? {} : { color: error?.message ? '#FE7070' : field?.value ? '#347AF0' : '#51566D' }}
                                    className={type === 'filterInput' ? 'date-selector-label' : 'modal-date-selector-label'}>
                                    {label}
                                </p>
                            }

                            <button
                                disabled={disabled}
                                onClick={handleClick}
                                style={
                                    disabled ? { borderColor: 'rgba(0, 0, 0, 0.26)' } :
                                        error?.message ? { borderColor: '#FE7070' } :
                                            type === 'filterInput' ? {
                                                width: '165px',
                                                justifyContent: 'space-between',
                                            } : {}}
                                className={type === 'filterInput' ? 'date-range-selector' : 'modal-date-range-selector'}
                                aria-describedby={id}
                                type={'button'}
                            >
                                <div className="date-range-inputs"
                                     onClick={(e) => {
                                         e.stopPropagation();
                                         e.preventDefault();
                                     }}
                                >
                                    <DateInput
                                        min={min}
                                        max={max}
                                        disabled={disabled}
                                        placeholder={'MM/yyyy'}
                                        format="MM/yyyy"
                                        value={field?.value ? moment(field?.value).toDate() : null}
                                        onChange={(date) => {
                                            const isValid = isValidDate(date);
                                            const minDate = min ? new Date(min) : null;
                                            const maxDate = max ? new Date(max) : null;
                                            const isWithinRange = (!minDate || date >= minDate) && (!maxDate || date <= maxDate);

                                            if (isValid && isWithinRange) {
                                                const formattedDate = moment(date).format('YYYY-MM');
                                                field.onChange(formattedDate);
                                            }
                                        }}
                                    />
                                </div>
                                <div className="flex-align-center">
                                    {field?.value &&
                                        <button className="remove-calendar-day" type={'button'} onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            field.onChange(null);
                                        }}>
                                            <img src={Images.removeBlack} alt="remove" />
                                        </button>
                                    }
                                    <img
                                        style={{ opacity: disabled ? 0.7 : 1 }}
                                        src={Images.calendarToday} alt="icon" />
                                </div>
                            </button>
                            <ErrMessage text={error?.message} />
                            <Popper
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                className={'modal-custom-date-selector-box'}
                                placement="bottom-start"
                                style={{ position: 'absolute', zIndex: 1400 }}
                            >
                                <Calendar
                                    date={field?.value ? moment(field?.value, 'YYYY-MM').toDate() : null}
                                    onChange={(date) => {
                                        const formattedDate = moment(date).format('YYYY-MM');
                                        field.onChange(formattedDate);
                                        setAnchorEl(null); // Close calendar after selecting
                                    }}
                                    locale={customLocale}
                                    maxDate={max ? newDateWithoutUtc(max) : undefined}
                                    minDate={min ? newDateWithoutUtc(min) : undefined}
                                    shownDate={field?.value ? moment(field?.value, 'YYYY-MM').toDate() : undefined}
                                    renderDayContents={(day) => {
                                        // Hide individual days
                                        return null;
                                    }}
                                />
                                <button type={'button'} className="clear-bnt"
                                        onClick={() => field.onChange(null)}>Clear
                                </button>
                            </Popper>
                            {open && <div className="beck-drop" onClick={() => setAnchorEl(null)} />}
                        </div>
                    </>
                );
            }}
        />
    );
};
