import React, { useContext } from 'react';
import clsx from 'clsx';
import { navBarStyles } from './style';
import { Drawer, IconButton, List } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import { GlobalLogo, SimpleTooltip } from 'components';
import { Colors, DrawerContext, Images, superAdminRouters, useModal, useSecondaryModal } from 'utils';
import { CustomListItem } from './common';
import { useSelector } from 'react-redux';
import { SupportModal } from './common/supportModal';

export const LeftBar = ({}) => {
    const classes = navBarStyles();
    const { open, handleDrawerOpenClose } = useContext(DrawerContext);
    const company = useSelector((state) => state.system.company);
    const { openSecondary } = useSecondaryModal();

    return (
        <div>
            <div className={classes.expandCollapseButtonBox}>
                <SimpleTooltip
                    title={<p>{open ? 'Collapse' : 'Expand'}</p>}
                    placement="right-start"
                    arrow
                >
                    <div
                        style={{ transition: 'all .3s' }}
                        className={open ? classes.transitionOpen : classes.transitionClose}
                    >
                        <IconButton
                            style={{
                                background: Colors.ThemeBlue,
                                border: `2px solid ${Colors.TextWhite}`,
                            }}
                            className={classes.IconButtonStyle}
                            onClick={handleDrawerOpenClose}
                        >
                            {open === false ? <ChevronRight /> : <ChevronLeft />}
                        </IconButton>
                    </div>
                </SimpleTooltip>
            </div>

            <Drawer
                variant="permanent"
                className={clsx(classes.drawer, {
                    [classes.drawerOpen]: open,
                    [classes.drawerClose]: !open,
                })}
                classes={{
                    paper: clsx({
                        [classes.drawerOpen]: open,
                        [classes.drawerClose]: !open,
                    }),
                }}
            >
                <div className={classes.drawerBox}>
                    <div>
                        <div className={classes.companyInfoBox}>
                            {company?.name &&
                                <>
                                    <div className={classes.companyInfoWrapper}>
                                        <div
                                            className={company?.logo?.thumbUrl ? classes.companyLogo : classes.companyBox}>
                                            <img src={company?.logo?.thumbUrl ? company?.logo?.thumbUrl : Images.office}
                                                 alt="icon" />
                                        </div>
                                        {open && <p>{company?.name ? company?.name : ''}</p>}
                                    </div>
                                </>
                            }
                        </div>
                        <List className={classes.menuItems}>
                            {superAdminRouters.map((item, index) => {
                                if (item) {
                                    return (
                                        <CustomListItem
                                            key={index}
                                            item={item}
                                            accordion={item?.type === 'accordion'
                                              // item.name === 'Billing' ||
                                              //   item.name === 'Posting' ||
                                              //     item.name === 'Payroll'
                                        }
                                        />
                                    );
                                }
                            })}
                        </List>
                    </div>

                    <div style={{position:'relative', zIndex: 1205}}>
                        <div
                            style={{padding: '0 8px'}}
                            onClick={() => window.open('/help-center', '_blank')}
                            className={classes.helpCenterBox}
                        >
                            <button
                                style={{ width: open ? '100%' : 'auto' }} className={classes.helpCenterButton}>
                                <div><img src={Images.helpIcon} alt="" /></div>
                                {open && <p>Help Center</p>}
                            </button>
                        </div>
                        <div

                            onClick={() => openSecondary(<SupportModal/>)}
                            // onClick={() => window.open('https://share.hsforms.com/1PLQ8UhkYTWKqJZM7T9x0jQslkl2', '_blank')}
                            className={classes.helpCenterBox}
                        >
                            <button
                                style={{ width: open ? '100%' : 'auto' }} className={classes.helpCenterButton}>
                                <div><img src={Images.onlineSupport} alt="" /></div>
                                {open && <p>Support</p>}
                            </button>
                        </div>

                        <div style={{ height: 30 }}>
                            <GlobalLogo open={open} />
                        </div>
                    </div>
                </div>
            </Drawer>
        </div>
    );
};
