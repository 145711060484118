import { v4 as uuidv4 } from 'uuid';
import { CLIENT_ACTION, renderClientName } from './constants';


export const signatureDetailReport = () => {
    return {
        title: 'Signature Report',
        url: '/reports/appt/signature/pdf',
        list:
            [
                {
                    required: true,
                    type: 'select',
                    name: 'client',
                    label: 'Client',
                    loadType: CLIENT_ACTION,
                    renderValue: (option) => renderClientName(option),
                },
                {
                    type: 'monthDate',
                    name: 'date',
                    startLabel: 'Date',
                    required: true,
                },
            ],
    };
};


export const signatureReportEnums = [
    {
        id: uuidv4(),
        name: 'Signature Report',
        description: 'List of Signatures.',
        generateInfo: signatureDetailReport(),
    },

];