import React, { forwardRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { InputAdornment, TextField } from '@material-ui/core';
import { Svg } from 'assets';

const getNestedError = (obj, path) => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const Input = forwardRef(
    ({
         label, labelHeader, placeholder, rules, control, name, type = 'text', maxLength, minLength,
         startAdornment, endAdornment, className, onClickIconStart, onClickIconEnd, showPassword, disabled,
         min, max, ...anotherProps
     }, ref) => {
        const [isFocused, setIsFocused] = useState(false);


        const inputProps = {};

        if (maxLength) inputProps.inputProps = maxLength;
        if (minLength) inputProps.minLength = minLength;
        if (min) inputProps.min = min;
        if (max) inputProps.max = max;

        const EndIcon = showPassword ? Svg.EyeSvg : Svg.EyeHideSvg;

        const typePassword = showPassword ? 'text' : 'password';

        return (
            <Controller
                control={control}
                name={name}
                rules={rules}
                render={({ field, formState: { errors } }) => {
                    const error = getNestedError(errors, name);

                    return (
                        <div
                            className={`main-input-wrapper  ${className ? className : ''}`}
                            ref={ref}
                        >
                            {labelHeader &&
                                <p className={`input-main-label-header ${error?.message ? 'errorLabel' : ''}`}>
                                    {labelHeader}
                                    {rules?.required && <span className='input-main-label-header-required'>*</span>}
                                </p>
                            }
                            <TextField
                                ref={ref}
                                {...anotherProps}
                                {...field}
                                disabled={disabled}
                                autoComplete='on'
                                error={!!error?.message}
                                type={showPassword ? typePassword : type}
                                value={field.value}
                                onFocus={() => setIsFocused(true)}
                                onBlur={() => setIsFocused(false)}
                                placeholder={placeholder}
                                label={label}
                                inputProps={{ ...inputProps,   style: { color: '#4B5C68' } }}
                                InputLabelProps={{
                                    shrink: (type === 'date' || type === 'time' || type === 'month') ? true : !!field?.value || isFocused,
                                }}

                                InputProps={{
                                    startAdornment: startAdornment &&
                                        <InputAdornment
                                            onClick={onClickIconStart}
                                            className={`${error?.message ? 'error-svg' : ''}`}
                                            position='start'
                                        >
                                            {startAdornment}
                                        </InputAdornment>,
                                    endAdornment:
                                        (endAdornment && <InputAdornment
                                            onClick={onClickIconEnd}
                                            position='end'
                                            className={`${error?.message ? 'error-svg' : ''}`}
                                        >
                                            {endAdornment}
                                        </InputAdornment>)
                                        || (type === 'password'
                                            && <InputAdornment
                                                onClick={onClickIconEnd}
                                                position='end'
                                                className={`${error?.message ? 'error-svg-password' : ''}`}
                                            >
                                                <EndIcon />
                                            </InputAdornment>),
                                }}
                            />
                            <p className='error-message'>{error?.message}</p>
                        </div>
                    );
                }
                }
            />
        );
    });

Input.displayName = 'Input';


