import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clientActions } from 'store';
import { CardHeader, cardStyle, getCardBorderTop, NoYet, CustomDelete, Address, } from 'components';
import { Images, PermissionList, RolePermission, useModal, } from 'utils';
import { serviceSingleStyles } from './styles';
import { ClientAddressModal } from '../../clientModals';

const DELETE_ACTION_TYPE = 'DELETE_CLIENT_ADDRESS';

export const ClientAddress = ({}) => {
    const cardClasses = cardStyle();
    const classes = serviceSingleStyles();
    const dispatch = useDispatch();
    const borderTop = getCardBorderTop('Address');
    const clientById = useSelector((state) => state.client.clientItemInfo);
    const defaultAddress = clientById?.addresses?.find((i) => i?.default);
    const otherAddress = clientById?.addresses?.filter((i) => i?.default === false);
    const { open } = useModal();

    const deleteAddress = (item) => {
        open(<CustomDelete
            text="Are you sure you want to delete the address ?"
            info="Delete Address"
            handleDel={() => dispatch(clientActions.deleteAddress(clientById?.id, item?.id))}
            handleClose={() => close()}
            actionType={DELETE_ACTION_TYPE}
            body={
                <div className="deleting-client-info-box" style={{alignItems:'flex-start'}}>
                    <img style={{marginTop:'3px'}} src={Images.removeAddress} alt="icon" />
                    <p style={{fontWeight:400}}>{item?.address?.formattedAddress}</p>
                </div>
            }
        />);
    };

    return (
        <>
            <div
                className={cardClasses.card}
                style={{ width: '32.5%', maxWidth: '510px' || 210, height: 'auto', minHeight: '430px' }}
            >
                <>
                    <div className={cardClasses.headerBorder} style={{ borderTop }} />
                    <div className={classes.spaceBetween}>
                        <CardHeader
                            color={'#FFCA33'}
                            title={'Address'}
                            icon={Images.address}

                        />
                        {RolePermission([PermissionList.CLIENT_CREATE?.code]) &&
                            <button onClick={() => open(<ClientAddressModal />)} className={classes.addButton}>
                                <img src={Images.addIcon} alt="icon" />
                            </button>
                        }
                    </div>
                </>

                <div className={cardClasses.cardBody}>
                    {clientById?.addresses?.length ?
                        <div className={classes.accordionItem}>
                            {defaultAddress ?
                                <>
                                    <div className={classes.titleAndActions}>
                                        <div>
                                            {defaultAddress?.name &&
                                                <p className={classes.boldText}>{defaultAddress?.name}</p>
                                            }
                                            <p className={classes.regularText}>
                                                {defaultAddress?.address?.street ?
                                                    `${defaultAddress?.address?.street}${defaultAddress?.address?.unitNumber ? `, Unit ${defaultAddress?.address?.unitNumber},` : ''}`
                                                    : ''
                                                }
                                            </p>
                                            <p className={classes.regularText}>{`
                                                ${defaultAddress?.address?.city ? defaultAddress?.address?.city : ''}
                                                ${defaultAddress?.address?.state ? defaultAddress?.address?.state : ''}
                                                ${defaultAddress?.address?.zip ? defaultAddress?.address?.zip : ''}               
                                                ${defaultAddress?.address?.unit ? defaultAddress?.address?.unit : ''}               
                                           `} </p>
                                        </div>
                                        {RolePermission([PermissionList.CLIENT_CREATE?.code]) &&
                                            <div className={classes.buttonsWrapper}>
                                                <button onClick={() => deleteAddress(defaultAddress)}>
                                                    <img src={Images.remove} alt="icon" />
                                                </button>
                                                <button onClick={() => open(<ClientAddressModal
                                                    currentAddress={defaultAddress} />)}>
                                                    <img src={Images.edit} alt="icon" />
                                                </button>
                                            </div>
                                        }
                                    </div>

                                    {otherAddress?.length ?
                                        <p className={classes.otherAddresses}>Other addresses</p>
                                        :
                                        ''
                                    }
                                </>
                                :
                                ''
                            }

                            {otherAddress?.map((i, j) => (
                                <React.Fragment key={j}>
                                    <div style={{ background: '#F2F4F8' }} className={classes.titleAndActions}>
                                        <div>
                                            {i?.name && <p className={classes.boldText}>{i?.name}</p>}
                                            <p className={classes.regularText}>
                                                {i?.address?.street ? `${i?.address?.street}${i?.address?.unitNumber ? `, Unit ${i?.address?.unitNumber},` : ''}` : ''}
                                            </p>
                                            <p className={classes.regularText}>{`
                                                ${i?.address?.city ? i?.address?.city : ''}
                                                ${i?.address?.state ? i?.address?.state : ''}
                                                ${i?.address?.zip ? i?.address?.zip : ''}               
                                           `} </p>
                                        </div>
                                        {RolePermission([PermissionList.CLIENT_CREATE?.code]) &&
                                            <div className={classes.buttonsWrapper}>
                                                <button onClick={() => deleteAddress(i)}>
                                                    <img src={Images.remove} alt="icon" />
                                                </button>
                                                <button onClick={() => open(<ClientAddressModal currentAddress={i} />)}>
                                                    <img src={Images.edit} alt="icon" />
                                                </button>
                                            </div>
                                        }
                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                        :
                        <NoYet text="No Addresses Yet" />
                    }
                </div>
            </div>
        </>
    );
};