import React from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Images, useModal } from 'utils';
import { appointmentActions } from 'store';
import { linksNotYet } from './constants';
import { AddButton, AddCircleBlue, CustomDelete, NoItemsYet, SimpleTooltip } from 'components';
import { LinksModal } from './linksModal';
import { SERVICE } from '../../../constants';

const ACTION_TYPE = 'EDIT_APPOINTMENT';
export const ScheduleLinks = ({ item }) => {
    const { open } = useModal();
    const dispatch = useDispatch();

    const apptInfo = () => {
        const params = item?.type === SERVICE ?
            {
                ...item,
                client: item.client?.id,
                staff: item.staff?._id,
                placeService: item.placeService?._id,
                authorizedService: item?.authorizedService?._id,
                authService: item?.authorizedService?.authorization?._id,
                chargeRate: item?.chargeRateId,
                payCodeType: item?.payCodeType?._id,
            }
            :
            {
                type: item?.type,
                staff: item?.staff?._id,
                payCodeType: item?.payCodeType?._id,
                startDate: item?.startDate,
                startTime: item?.startTime,
                endTime: item?.endTime,
            };
        params.miles = item?.miles ? +item?.miles : 0;

        if (item?.type === SERVICE) {
            delete params.payer;
            delete params.removedFromClaimFlag;
            delete params.incompleteUnitFlag;
            delete params.billStatus;
            delete params.displayId;
            delete params.status;
            delete params.id;
            delete params.miles;
            delete params.template;
            delete params.units;
        }

        return params;
    };


    const handleCopy = (link) => {
        navigator.clipboard.writeText(link)
            .then(() => {
                toast('Link copied to clipboard');
            });
    };

    const handleDelete = (it) => {
        open(<CustomDelete
                text="Are you sure you want to delete the link?"
                info="Delete Link?"
                handleDel={() => dispatch(appointmentActions.editAppointment({
                        ...apptInfo(),
                        links: item?.links?.filter((i) => i?._id !== it?._id),
                    }, item?.id),
                )}
                handleClose={close}
                actionType={ACTION_TYPE}
                innerText={'Delete'}
                body={
                    <div className="deleting-client-info-box"
                         style={{ alignItems: 'flex-start' }}>
                        <img style={{ marginTop: '3px' }} src={Images.externalLink}
                             alt="icon" />
                        <p style={{ fontWeight: 400 }}>{it?.title}</p>
                    </div>
                }
            />,
        );
    };

    return (
        <div>
            <div className="space-between-align-start">
                <p className="attached-links-title" style={{ marginBottom: '24px' }}>Attached Links</p>
                <AddButton
                    Icon={true}
                    customIcon={Images.addCircle}
                    text={'Add Link'}
                    handleClick={() => open(<LinksModal modalDate={item} apptInfo={apptInfo()} />)}
                />
            </div>

            <div className='attached-link-body'>
            {item?.links?.length ? item?.links?.map((i, j) => (
                    <div key={j} className="attached-link-box">
                        <div className="attached-link-name">
                            <a key={j}
                               target={'_blank'}
                               href={i?.url}
                               className="schedule-link-wrapper">
                                <img src={Images.externalLink} alt="icon" />
                                <p> {i?.title}</p>
                            </a>
                        </div>
                        <div className="flex-align-center" style={{ gap: 8 }}>
                            <SimpleTooltip title={<p>Edit Link</p>} placement="top-center" arrow>
                                <button
                                    className="new-action-btn-box-small"
                                    onClick={() => open(<LinksModal modalDate={item} link={i} apptInfo={apptInfo()} />)}
                                >
                                    <img src={Images.editPen} alt="icon" />
                                </button>
                            </SimpleTooltip>
                            <SimpleTooltip title={<p>Copy Link</p>} placement="top-center" arrow>
                                <button
                                    className="new-action-btn-box-small"
                                    onClick={() => handleCopy(i?.url)}
                                >
                                    <img src={Images.copy} alt="icon" />
                                </button>
                            </SimpleTooltip>
                            <SimpleTooltip title={<p>Delete Link</p>} placement="top-center" arrow>
                                <button
                                    className="new-action-btn-box-small"
                                    onClick={() => handleDelete(i)}
                                >
                                    <img src={Images.grayTrash} alt="icon" />
                                </button>
                            </SimpleTooltip>
                        </div>
                    </div>
                ))
                :
                <NoItemsYet
                    height={linksNotYet?.height}
                    text={linksNotYet.title}
                    // subTitle={linksNotYet.subTitle}
                    image={linksNotYet.image}
                />
            }
            </div>
        </div>
    );
};