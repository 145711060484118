import React from 'react';
import { DownloadFile, Images } from 'utils';
import { TextRow, DateRow, SimpleTooltip, DateRowStartEnd, MinLoader, DateTimeRow, TypeRow } from 'components';
import { GenerateReportBtn } from './core';
import moment from 'moment/moment';

export const tabsLabels = [
    {
        label: 'Generated Reports',
    },
    {
        label: 'Clients',
    },
    {
        label: 'Staff',
    },
    {
        label: 'Billing',
    },
    {
        label: 'Payroll',
    },
    {
        label: 'System',
    },
    {
        label: 'Signature',
    },

];

/** General Reports */

export const GeneralReportsHead = () => {
    return [
        { name: 'name', title: 'Report Name', custom: false },
        { name: 'category', title: 'Category', custom: false },
        { name: '', title: 'Date/Time Generated', width: '200px', custom: false },
        { name: '', title: 'Date Range', width: '200px', custom: false },
        { name: '', title: 'Action', custom: false, width: '120px' },
    ];
};

export const GeneralReportsBody = (openDelete) => {
    const { currentId, loadDownload, handleDownload } = DownloadFile();

    return [
        { rowText: (item) => <TextRow name={item?.name} textWidth={8} /> },
        { rowText: (item) => <TypeRow text={item?.category} /> },
        { rowText: (item) => <DateTimeRow date={item?.generatedDate} /> },
        {
            rowText: (item) => item?.dateRange?.startDate || item?.dateRange?.endDate ? `
        ${item?.dateRange?.startDate ? moment.utc(item?.dateRange?.startDate).format('MM/DD/YYYY') : 'N/A'}
        -
        ${item?.dateRange?.endDate ? moment.utc(item?.dateRange?.endDate).format('MM/DD/YYYY') : 'N/A'}`
                :
                'N/A',
        },
        {
            rowText: (item) => (
                <div className="flex-align-center" style={{ gap: 8 }}>
                    <button className="reset-btn" style={{ width: 24 }}
                            onClick={() => handleDownload({
                                ...item?.file,
                                originalName: `${item?.file?.originalName}${item?.file?.format ? item?.file?.format : ''}`,
                            }, item?.id)}>
                        {currentId === item?.id && loadDownload ?
                            <MinLoader color={'#0C66E4'} margin={'0'} position={'relative'} small={true} />
                            :
                            <img style={{ width: 24, height: 24 }} src={Images.downloadCloudBlue} alt="download" />
                        }
                    </button>
                    <button className="reset-btn" onClick={() => openDelete(item)}>
                        <img src={Images.redTrash} alt="remove" />
                    </button>
                </div>
            ),
        },
    ];
};


export const GENERAL_REPORT_ACTION_TYPE = 'GET_GENERAL_REPORTS';
export const DELETE_GENERAL_REPORT = 'DELETE_GENERAL_REPORT';

export const GeneralReportNotYet = {
    title: 'No General Reports Available',
    subTitle: 'It looks like there are currently no general reports available. ',
    image: Images.notFile,
};

/** End */


/** Signature Reports */

export const signatureReportHead = [
    { name: 'client', title: 'Client Name', custom: false, noSearch: true },
    { name: 'staff', title: 'Staff Name', custom: false, noSearch: true },
    { name: '', title: 'Signature D/T', custom: false, disabled: true, noSearch: true },
    { name: '', title: 'Appointment D/T', custom: false, disabled: true, noSearch: true },
    { name: 'cptCode', title: 'CPT Code', custom: false, noSearch: true },
    { name: '', title: 'Signature Files', custom: false, disabled: true, noSearch: true },
    { name: '', title: 'Action', custom: false, disabled: true, noSearch: true, width: '100px' },
];

export const signatureReportBody = [
    { rowText: (item) => <TextRow name={item?.client ? item?.client : ''} /> },
    { rowText: (item) => <TextRow name={item?.staff ? item?.staff : ''} /> },
    { rowText: (item) => <DateRow date={item?.signatureDate} /> },
    { rowText: (item) => <DateRow date={item?.apptDate} /> },
    { rowText: (item) => <TextRow name={item?.cptCode ? item?.cptCode : ''} /> },
    {
        rowText: (item) => <div className={'signature-file-wrapper'}>{item?.signature ?
            <img src={item?.signature} alt="icon" />
            :
            <p style={{ color: '#475467' }}>No Signature</p>
        }
        </div>,
    },

    {
        rowText: (item) => (
            <SimpleTooltip
                title={item?.signature ? 'View Signature' : 'No Signature Available'}
                placement={'top-start'}
            >
                <button style={!item?.signature ? { opacity: '0.5' } : {}} className="reset-btn"
                        disabled={!item?.signature}>
                    <a href={item?.signature} target={'_blank'}>
                        <img src={Images.showEye} alt="icon" className="show-eye-blue-icon" />
                    </a>
                </button>
            </SimpleTooltip>
        ),
    },
];

export const SIGNATURE_REPORT_ACTION_TYPE = 'GET_SIGNATURE_REPORTS';


export const signatureReportNotYet = {
    title: 'No Signature Reports Available',
    subTitle: 'It looks like there are currently no signature reports available. ',
    image: Images.notFile,
};

/** End */

// /** Client Reports */
//
// export const ClientReportsHead = [
//     { name: 'name', title: 'Report Name', custom: false },
//     { name: '', title: 'Description', custom: false },
//     { name: '', title: 'Action', custom: false, width: '140px'},
// ];
//
// export const ClientReportsBody = (openDelete) => {
//     return [
//         { rowText: (item) => <TextRow name={item?.name} textWidth={8} /> },
//         { rowText: (item) => <TextRow name={item?.description} textWidth={7} /> },
//         { rowText: (item) => <GenerateReportBtn handleClick={() => openDelete(item)} text={'Generate'}/> },
//     ];
// };
//
// export const clientReportNotYet = {
//     title: 'No Client Reports Available',
//     subTitle: 'It looks like there are currently no client reports available. ',
//     image: Images.notFile,
// };
//
//
// /** End */

/** Global Reports */

export const ReportsHead = [
    { name: 'name', title: 'Report Name', custom: false },
    { name: '', title: 'Description', custom: false },
    { name: '', title: 'Action', custom: false, width: '140px' },
];

export const ReportsBody = (openGenerate) => {
    return [
        { rowText: (item) => <TextRow name={item?.name} textWidth={8} /> },
        { rowText: (item) => <TextRow name={item?.description} textWidth={7} /> },
        { rowText: (item) => <GenerateReportBtn handleClick={() => openGenerate(item)} text={'Generate'} /> },
    ];
};

export const ReportsNotYet = (type) => {
    return {
        title: `No ${type} Reports Available`,
        subTitle: `It looks like there are currently no ${type} reports available. `,
        image: Images.notFile,
    };
};

/** End */