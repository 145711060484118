import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import {ClientItem} from "fragments/client";
import { clientActions, fundingSourceActions, noteActions, adminActions, availabilityScheduleActions } from 'store';
import {Loader} from "components";
import {FindLoad, PermissionList, RolePermission} from "utils";

export const ClientSingle = () => {
    const dispatch = useDispatch();
    const params = useParams();
    const loader = FindLoad("GET_CLIENT_BY_ID");

    useEffect(() => {
        dispatch(clientActions.getClientsById(params.id));
        // dispatch(adminActions.getAdmins({status: "ACTIVE"}));

        if(RolePermission([PermissionList.CLIENT_NOTE_READ?.code, PermissionList.CLIENT_NOTE_MANAGE?.code])) {
            dispatch(noteActions.getGlobalNotes(params.id, "Client"));
        }
        if(RolePermission([PermissionList.CLIENT_ENROLLMENT_MANAGE?.code, PermissionList.CLIENT_ENROLLMENT_READ?.code])) {
            dispatch(clientActions.getClientsEnrollment(params.id));
            dispatch(fundingSourceActions.getFundingSource({status: "ACTIVE"}));
        }
        if(RolePermission([PermissionList.CLIENT_AUTHORIZATION_READ?.code, PermissionList.CLIENT_AUTHORIZATION_MANAGE?.code])) {
            dispatch(clientActions.getClientsAuthorizations(params.id, ));
        }
        if(RolePermission([PermissionList.CLIENT_FILE_READ?.code, PermissionList.CLIENT_FILE_MANAGE?.code])) {
            dispatch(clientActions.getClientDocument(params.id))
        }

        dispatch(availabilityScheduleActions.getAvailabilitySchedule(params.id, "Client"))
    }, []);

    if (loader.length) {return <Loader/>}

    return (
        <ClientItem/>
    );
};
