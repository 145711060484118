import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { claimActions, httpRequestsOnSuccessActions, systemActions } from 'store';
import { FullTable, SelectActions } from 'components';
import { ACTION_TYPE, pendingClaimHead, pendingClaimBody, pendingClaimNotYet } from './constants';
import { FindSuccessItem, PermissionList, RolePermission, useModal } from 'utils';
import { SelectSubmissionMethod } from './core';

const GENERATE_ACTION = 'GENERATE_CLAIM';

export const PendingClaims = () => {
    const { open, close } = useModal();
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const claimsData = useSelector((state) => state.claim.claims);
    const [selectedList, setSelectedList] = useState([]);
    const filteredList = claimsData?.claims?.filter((i) => (!i?.incompleteUnitFlag));
    const generateClaimSuccess = FindSuccessItem(GENERATE_ACTION);

    const renderParams = () => {
        const sendInfo = {
            ...info,
            status: 'PENDING',
        };
        delete sendInfo.page;
        delete sendInfo.tabType;
        sendInfo.skip = info?.skip ? info?.skip : 0;
        sendInfo.limit = 50;
        return sendInfo;
    };

    useEffect(() => {
        dispatch(systemActions.getOfficeAlly());
    }, []);

    useEffect(() => {
        getPendingClaim();
    }, [info]);

    useEffect(() => {
        if (generateClaimSuccess) {
            getPendingClaim('noLoad');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(GENERATE_ACTION));
            close();
        }
    }, [generateClaimSuccess]);


    const getPendingClaim = (loading) => {
        dispatch(claimActions.getPendingClaims({ ...renderParams(), loading }));
    };

    const handleCheck = (ev, item, name) => {
        if(name === 'selectAll') {
            let allList = filteredList?.map((i) => i?.id);
            setSelectedList(allList);
        }else {
            if (name === 'all') {
                let allList = filteredList?.length === selectedList?.length ? [] : filteredList?.map((i) => i?.id);
                setSelectedList(allList);
            } else {
                const list = [...selectedList];
                if (ev.target.checked) {
                    list.push(item?.id);
                } else {
                    list.indexOf(item?.id) !== -1 && list.splice(list.indexOf(item?.id), 1);
                }
                setSelectedList(list);
            }
        }
    };

    const removeSelectedList = () => {
        setSelectedList([])
    }

    return (
        <div>
            {!!selectedList?.length &&
                RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
                <SelectActions
                    selectedList={selectedList}
                    selectAll={handleCheck}
                    submitClaims={() => open(
                         <SelectSubmissionMethod
                             selectedList={selectedList}
                             afterSuccess={() => {
                                 getPendingClaim('noLoad')
                                 removeSelectedList()
                             }}
                         />
                    )}
                    removeSelected={removeSelectedList}
                />
            }
            <div className="pending-claim-box">
                <FullTable
                    head={pendingClaimHead(handleCheck, filteredList, selectedList)}
                    body={pendingClaimBody(handleCheck, claimsData?.claims, selectedList, history)}
                    loadingType={ACTION_TYPE}
                    list={claimsData?.claims}
                    listCount={claimsData?.count}
                    handleClick={(id) =>
                        history.push(`/claim/${id}`, { tabType: info?.tabType })
                    }
                    notYet={pendingClaimNotYet}
                    activeRowId={''}
                />
            </div>
        </div>
    );
};
