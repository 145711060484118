import { renderColor } from './colors';
import React from 'react';
import { Popover } from '@material-ui/core';

export const TagBox = ({ tags, noTool }) => {
    const list = noTool ? tags : tags?.length > 3 ? tags.slice(0, 3) : tags;
    const croppedList = noTool ? null : tags?.length > 3 ? tags.slice(0, 3) : null;
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    if (!tags?.length) {
        return null;
    }

    return (
        <div className="tags-box-wrapper">
            {list?.map((tag, index) => (
                <div
                    style={{
                        background: renderColor(tag?.color).background,
                        color: renderColor(tag?.color).color,
                    }}
                    key={index}
                    className="tag-box"
                >
                    {tag?.name}
                </div>
            ))}

            {croppedList &&
                <button
                    aria-owns={open ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    className="tag-count"
                >
                    +{tags.length - 3}
                </button>
            }

            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
            >
                <div className="tag-more-info-box">
                    {croppedList?.map((tag, index) => (
                        <div
                            style={{
                                background: renderColor(tag?.color).background,
                                color: renderColor(tag?.color).color,
                            }}
                            key={index}
                            className="tag-box"
                        >
                            {tag?.name}
                        </div>
                    ))}
                </div>
            </Popover>

        </div>
    );
};