import React from 'react';
import { Images } from 'utils';


export const NotError = ({errMessage, type}) => {
    return (
        <div className="flex-align-center">
            <img src={type === 'error' ? Images.errorIcon : Images.successIcon} alt="success" />
            <p className="toast-text">{errMessage}</p>
        </div>
    );
};