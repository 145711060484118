import {
    CREATE_ADMIN,
    EDIT_ADMIN_BY_ID,
    GET_ADMIN_BY_ID,
    GET_ADMINS,
    CREATE_CREDENTIAL,
    GET_CREDENTIAL,
    EDIT_CREDENTIAL_BY_ID,
    DELETE_CREDENTIAL_BY_ID,
    GET_EMPLOYMENT,
    CREATE_EMPLOYMENT,
    GET_PAY_CODE,
    CREATE_PAY_CODE,
    EDIT_EMPLOYMENT,
    CREATE_STAFF_SERVICE,
    GET_STAFF_SERVICE,
    DELETE_STAFF_SERVICE,
    GET_TIMESHEET,
    CREATE_TIMESHEET,
    GET_ALL_PAY_CODES,
    EDIT_TIMESHEET,
    GET_ALL_ADMINS,
    EDIT_PAY_CODE,
    IS_CLINICIAN,
    GET_TIMESHEET_BY_ID,
    CLEAR_ALL_PAY_CODES,
    CHANGE_ADMIN_STATUS,
    TERMINATE_PAY_CODE,
    CREATE_ADMIN_DETAILS,
    UPLOAD_STAFF_DOCUMENT,
    GET_STAFF_DOCUMENT,
    DELETE_STAFF_DOCUMENT,
    EDIT_STAFF_DOCUMENT,
    REMOVE_TIMESHEET_BY_ID,
    GET_FOUNDING_ALL_SERVICES,
    REINVITE_ADMIN,
    DELETE_EMPLOYMENT,
    UPLOAD_STAFF_AVATAR,
    GET_STAFF_FOR_APPT, GET_BASIC_ADMINS, GET_BASIC_ACTIVE_ADMINS,
} from './admin.types';

/** Staff */

export const createAdmin = (body) => {
    return {
        type: CREATE_ADMIN,
        payload: {body},
    };
};
export const reinviteAdmin = (id) => {
    return {
        type: REINVITE_ADMIN,
        payload: {id},
    };
};

export const createAdminDetails = (id, body) => {
    return {
        type: CREATE_ADMIN_DETAILS,
        payload: {id, body},
    };
};

export const editAdminById = (body, id) => {
    return {
        type: EDIT_ADMIN_BY_ID,
        payload: {body, id},
    };
};
export const uploadAvatar = (id, body) => {
    return {
        type: UPLOAD_STAFF_AVATAR,
        payload: {id, body},
    };
};

export const getAdmins = (data, load) => {
    return {
        type: GET_ADMINS,
        payload: {data, load},
    };
};

export const getAllAdmins = (data) => {
    return {
        type: GET_ALL_ADMINS,
        payload: {data},
    };
};
export const getBasicAdmins = (params) => {
    return {
        type: GET_BASIC_ADMINS,
        payload: {params},
    };
};

export const getBasicActiveAdmins = (params) => {
    return {
        type: GET_BASIC_ACTIVE_ADMINS,
        payload: {params},
    };
};

export const getAdminById = (adminId) => {
    return {
        type: GET_ADMIN_BY_ID,
        payload: {adminId},
    };
};

export const changeAdminStatus = (id, params) => {
    return {
        type: CHANGE_ADMIN_STATUS,
        payload: {id, params},
    };
};

/** End */

/** Staff Credentials */

export const createCredential = (body) => {
    return {
        type: CREATE_CREDENTIAL,
        payload: {body},
    };
};

export const getCredential = (credentialId, data, load) => {
    return {
        type: GET_CREDENTIAL,
        payload: {credentialId, data, load},
    };
};

export const editCredentialById = (body, id, credentialId) => {
    return {
        type: EDIT_CREDENTIAL_BY_ID,
        payload: {body, id, credentialId},
    };
};

export const deleteCredentialById = (id, credentialId) => {
    return {
        type: DELETE_CREDENTIAL_BY_ID,
        payload: {id, credentialId},
    };
};

/** End */

/** Staff Employment */

export const getEmployment = (id) => {
    return {
        type: GET_EMPLOYMENT,
        payload: {id},
    };
};

export const createEmployment = (body, id) => {
    return {
        type: CREATE_EMPLOYMENT,
        payload: {body, id},
    };
};

export const editEmployment = (body, id, staffId) => {
    return {
        type: EDIT_EMPLOYMENT,
        payload: {body, id, staffId},
    };
};

export const deleteEmployment = (id, staffId) => {
    return {
        type: DELETE_EMPLOYMENT,
        payload: {id, staffId},
    };
};

/** End */

/** Staff PayCode */

export const getPayCode = (id, data) => {
    return {
        type: GET_PAY_CODE,
        payload: {id, data},
    };
};

export const createPayCode = (body, id) => {
    return {
        type: CREATE_PAY_CODE,
        payload: {body, id},
    };
};

export const editPayCode = (body, id, payCodeId) => {
    return {
        type: EDIT_PAY_CODE,
        payload: {body, id, payCodeId},
    };
};

export const terminatePayCode = (payCodeId, id) => {
    return {
        type: TERMINATE_PAY_CODE,
        payload: {payCodeId, id},
    };
};

export const getAllPayCodes = (id) => {
    return {
        type: GET_ALL_PAY_CODES,
        payload: {id},
    };
};

export const clearAllPayCodes = () => {
    return {
        type: CLEAR_ALL_PAY_CODES,
    };
};

/** End */

/** Staff Service */

export const getStaffService = (id) => {
    return {
        type: GET_STAFF_SERVICE,
        payload: {id},
    };
};

export const createStaffService = (id, serviceId) => {
    return {
        type: CREATE_STAFF_SERVICE,
        payload: {id, serviceId},
    };
};

export const deleteStaffService = (id, serviceId) => {
    return {
        type: DELETE_STAFF_SERVICE,
        payload: {id, serviceId},
    };
};

/** End */

/** Staff Timesheet */

export const getTimesheet = (id, data, load) => {
    return {
        type: GET_TIMESHEET,
        payload: {id, data, load},
    };
};
export const getTimesheetById = (id) => {
    return {
        type: GET_TIMESHEET_BY_ID,
        payload: {id},
    };
};
export const removeTimesheetById = () => {
    return {
        type: REMOVE_TIMESHEET_BY_ID,
    };
};

export const createTimesheet = (body) => {
    return {
        type: CREATE_TIMESHEET,
        payload: {body},
    };
};

export const editTimesheet = (body, id) => {
    return {
        type: EDIT_TIMESHEET,
        payload: {body, id},
    };
};

/** End */

export const isClinician = (id, isClinical) => {
    return {
        type: IS_CLINICIAN,
        payload: {id, isClinical},
    };
};


/** Staff Documents */

export const uploadStaffDocument = (file, id) => {
    return {
        type: UPLOAD_STAFF_DOCUMENT,
        payload: {file, id}
    }
}

export const editStaffDocument = (id, dockId, file) => {
    return {
        type: EDIT_STAFF_DOCUMENT,
        payload: {id, dockId, file}
    }
}

export const getStaffDocument = (id) => {
    return {
        type: GET_STAFF_DOCUMENT,
        payload: {id}
    }
}

export const deleteStaffDocument = (staffId, id) => {
    return {
        type: DELETE_STAFF_DOCUMENT,
        payload: {staffId, id}
    }
}


/** End */

export const getAllServices = () => {
    return {
        type: GET_FOUNDING_ALL_SERVICES,

    }
}

export const getStaffForAppt = ( ) => {
    return {
        type: GET_STAFF_FOR_APPT,
    }
}