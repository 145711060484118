import React from 'react';
import { Checkbox } from '@material-ui/core';
import moment from 'moment';
import { formatAMPMNeW, Images, PermissionList, RolePermission } from 'utils';
import { ApptFlags, LinkRow, SimpleTooltip, TextRow } from 'components';
import { BREAK, DRIVE, PAID, SERVICE, SICK_TIME, STAFF, UNPAID } from '../constants';
import { ApptActionsWithModals } from './apptActionsWithModals';
import { apptDetailStatus } from './modals/apptDetail/constants';

export function getBorderColorAndText(status, type) {
    if (type === 'SERVICE') {
        switch (status) {
            case 'PENDING':
                return { color: '#6FD231', background: '#F1FAEA', text: 'Pending' };
            case 'COMPLETED':
                return { color: '#2A8E6D', background: '#EAF4F0', text: 'Completed' };
            case 'CANCELLED':
                return { color: '#A3B2BD', background: '#EAEAEA', text: 'Cancelled' };
            default:
                return { color: '', text: '' };
        }
    } else {
        switch (status) {
            case 'CANCELLED':
                return { color: '#A3B2BD', background: '#EAEAEA', text: 'Cancelled' };
            case 'PENDING':
                return { color: '#347AF080', background: '#EBF2FD', text: 'Pending' };
            case 'COMPLETED':
                return { color: '#347AF0', background: '#EBF2FD', text: 'Completed' };
            default:
                return { color: '', text: '' };
        }
    }
}

export function getCurrentText(type) {
    switch (type) {
        case DRIVE:
            return { cardText: 'Drive', detailText: 'Drive' };
        case PAID:
            return { cardText: 'Paid', detailText: 'Paid' };
        case BREAK:
            return { cardText: 'Break', detailText: 'Break' };
        case SERVICE:
            return { cardText: 'Service', detailText: 'Service' };
        case STAFF:
            return { cardText: 'Staff', detailText: 'Staff' };
        case UNPAID:
            return { cardText: 'Unpaid', detailText: 'Unpaid' };
        case SICK_TIME:
            return { cardText: 'Sick', detailText: 'Sick' };
        default:
            return { cardText: '', detailText: '' };
    }
}

export const apptEnums = {
    PENDING: 'PENDING',
    COMPLETED: 'COMPLETED',
    CANCELLED: 'CANCELLED',
};
export const apptBillingEnums = {
    BILLED: 'BILLED',
    NOT_BILLED: 'NOT_BILLED',
    CANCELLED: 'CANCELLED',
};

export const scheduleStatuses = [
    { id: 'All', name: 'All' },
    // { id: 'RENDERED', name: 'Rendered' },
    { id: apptEnums?.COMPLETED, name: 'Completed' },
    // { id: 'NOT_RENDERED', name: 'Not Rendered' },
    { id: apptEnums?.PENDING, name: 'Pending' },
    { id: apptEnums?.CANCELLED, name: 'Cancelled' },
];
export const scheduleStatusEnums = [
    // { id: 'RENDERED', name: 'Rendered' },
    { id: apptEnums?.COMPLETED, name: 'Completed' },
    // { id: 'NOT_RENDERED', name: 'Not Rendered' },
    { id: apptEnums?.PENDING, name: 'Pending' },
    { id: apptEnums?.CANCELLED, name: 'Cancelled' },
];

export const scheduleBillingStatuses = [
    { id: 'All', name: 'All' },
    { id: 'BILLED', name: 'Billed' },
    { id: 'NOT_BILLED', name: 'Not Billed' },
];

export const scheduleTypes = [
    // { id: 'All', name: 'All' },
    { id: SERVICE, name: 'Service' },
    { id: STAFF, name: 'Staff' },
    { id: DRIVE, name: 'Drive' },
    { id: BREAK, name: 'Break' },
    { id: PAID, name: 'Paid' },
    { id: UNPAID, name: 'Unpaid' },
    { id: SICK_TIME, name: 'Sick' },
];


export const apptNotYet = {
    title: 'No appointments yet',
    subTitle: 'It looks like there are no appointments on your schedule. Start by adding new appointments.',
    image: Images.noAppt,
};


export const apptHead = (handleSelect, fullList, selected) => {
    return [
        RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
        {
            renderView: <SimpleTooltip
                title={<p>Select All</p>}
                placement="top-start"
            >
                <Checkbox
                    onClick={(e) => handleSelect(e, '', 'all')}
                    name={'checkBox'}
                    style={{ padding: 0, color: '#347AF0' }}
                    checked={fullList?.length === selected?.length}
                />
            </SimpleTooltip>,
            width: '50px',
        },
        { name: 'displayId', searchKey: 'DISPLAY_ID', title: 'ID', custom: false, width: '180px', noSearch: true },
        {
            name: 'createdAt',
            title: 'Date/Time',
            searchKey: 'createdAt',
            iconType: 'date',
            icon: 'date',
            disabled: true,
            width: '120px',
            noSearch: true,
        },
        { name: '', title: 'Staff', custom: false, noSearch: true },
        { name: '', title: 'Client', custom: false, noSearch: true },
        { name: '', title: 'Payer', custom: false, noSearch: true },
        { name: 'type', title: 'Event', custom: false, width: '120px', noSearch: true },
        { name: '', title: 'CPT Code', custom: false, noSearch: true },
        { name: '', title: 'Status', custom: false, disabled: true, noSearch: true },
        { name: '', title: 'Actions', custom: false, disabled: true, width: '150px', noSearch: true },
    ];
};

export const apptBody = (handleSelect, fullList, selected, openEdit) => {
    return [
        RolePermission([PermissionList.CLAIMS_UPDATE?.code]) &&
        {
            button: (handle, item) => (
                <SimpleTooltip
                    title={<p>Select</p>}
                    placement="top-start"
                >
                    <Checkbox
                        onClick={(e) => handleSelect(e, item)}
                        name={'checkBox'}
                        style={{
                            padding: 0,
                            color: '#347AF0',
                        }}
                        checked={selected?.includes(item?.id)}
                    />
                </SimpleTooltip>
            ), notClickable: true,
        },
        {
            rowText: (item) =>
                <ApptFlags
                    fromAppt={true}
                    singleAppt={true}
                    id={item?.displayId}
                    incomplete={item?.incompleteUnitFlag}
                    removed={item?.removedFromClaimFlag}
                />,
        },
        {
            rowText: (item) => (
                <div>
                    <p>{moment.utc(item?.startDate).format('MM/DD/YYYY')}</p>
                    <p className="schedule-time">
                        {`${formatAMPMNeW(item?.startTime)}-${formatAMPMNeW(item?.endTime)}`}
                    </p>
                </div>
            ),
        },
        {
            rowText: (item) =>
                <LinkRow
                    name={item?.staff?.fullName}
                    href={RolePermission([PermissionList.STAFF_READ.code]) ? `/staff/${item?.staff?.id}` : ''}
                    checkPermission={item?.staff?.id ? RolePermission([PermissionList.STAFF_READ.code]) : null}
                    textWidth={8}
                />,
        },
        {
            rowText: (item) => item?.client?.fullName ?
                <LinkRow
                    name={item?.client?.fullName}
                    href={RolePermission([PermissionList.CLIENT_READ?.code]) ? `/client/${item?.client?.id}` : ''}
                    checkPermission={item?.client?.id ? RolePermission([PermissionList.CLIENT_READ?.code]) : null}
                    textWidth={8}
                />
                :
                'N/A',
        },
        {
            rowText: (item) => item?.payerName ?
                <LinkRow
                    name={item?.payerName}
                    href={RolePermission([PermissionList.FS_READ?.code]) ? `/fundingSource/${item?.payerId}` : ''}
                    checkPermission={item?.payerId ? RolePermission([PermissionList.FS_READ?.code]) : null}
                    textWidth={8}
                />
                :
                'N/A',
        },

        { rowText: (item) => getCurrentText(item?.type)?.detailText },

        {
            rowText: (item) => <TextRow name={item?.cptCode ? `${item?.cptCode} ${item?.chargeRateName ? `(${item?.chargeRateName})` : ''}` : 'N/A'} />,
        },
        {
            rowText: (item) => (
                <div>
                    {apptDetailStatus(item?.status, item, true)}
                </div>
            ),
        },
        {
            rowText: (item) => (<ApptActionsWithModals item={item} handleEdit={openEdit} afterSuccess={() => {
            }} />), notClickable: true,
        },
    ];
};

export const checkEditPermission = (item) => {
    return item?.type === SERVICE ? RolePermission([PermissionList.APPT_SERVICE_CREATE?.code, PermissionList.APPT_CONNECTED_CREATE?.code]) :
        item?.type === STAFF ? RolePermission([PermissionList.APPT_STAFF_CREATE?.code]) :
            item?.type === BREAK ? RolePermission([PermissionList.APPT_BREAK_CREATE?.code]) :
                item?.type === DRIVE ? RolePermission([PermissionList.APPT_DRIVE_CREATE?.code]) :
                    item?.type === PAID ? RolePermission([PermissionList.APPT_PTO_CREATE?.code]) :
                        item?.type === UNPAID ? RolePermission([PermissionList.APPT_UNPAID_CREATE?.code]) :
                            item?.type === SICK_TIME ? RolePermission([PermissionList.APPT_SICK_TIME_CREATE?.code]) :
                                false;
};
const renderStatus = (currentItem) => {
    const { color: borderColor, text } = getBorderColorAndText(currentItem?.status, currentItem?.type);
    return { color: borderColor, text };
};


export const convertTo24HourMinutes = (time) => {
    const [timePart, ampm] = time.split(' ');
    const [hours, minutes] = timePart.split(':').map(Number);
    let convertedHours = hours;

    if (ampm === 'PM' && hours !== 12) {
        convertedHours += 12;
    } else if (ampm === 'AM' && hours === 12) {
        convertedHours = 0;
    }

    // Return the time in minutes since midnight
    return convertedHours * 60 + minutes;
};


export const weekdayMap = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

export const findFromList = (date, availabilityData) => {

    if (!availabilityData) return false;

    const weekDay = moment(date).day();
    const day = weekdayMap[weekDay];
    const fromAvailability = availabilityData[day];

    if (!Array.isArray(fromAvailability) || fromAvailability.length === 0) return false;

    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12 || 12;
    const convertedTime = `${hours}:${minutes} ${ampm}`;
    const currentTimeInMinutes = convertTo24HourMinutes(convertedTime);

    return fromAvailability.some(({ from, to, available }) => {
        if (!available) return false; // Skip if not available
        const fromTimeInMinutes = convertTo24HourMinutes(from);
        const toTimeInMinutes = convertTo24HourMinutes(to);
        return currentTimeInMinutes >= fromTimeInMinutes && currentTimeInMinutes <= toTimeInMinutes;
    });
};
