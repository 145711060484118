import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { payrollActions } from 'store';
import { StaffEmpPaycodes } from './common';

export const StaffEmployment = ({}) => {
    const dispatch = useDispatch();
    const payCodes = useSelector((state) => state.admins.payCodes);

    useEffect(() => {
        dispatch(payrollActions.getPayCodeGlobal());
        // dispatch(adminActions.getPayCode(params?.id));
    }, []);

    return (
        <StaffEmpPaycodes payCodes={payCodes} />
    );
};
