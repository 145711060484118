import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Tabs, Tab, Box } from '@material-ui/core';
import { tabsStyles } from './styles';
import { SaveParams } from 'utils';

function TabPanel(props) {
    const { boxClassName, children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-force-tabpanel-${index}`}
            aria-labelledby={`scrollable-force-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className={boxClassName} p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `scrollable-force-tab-${index}`,
        'aria-controls': `scrollable-force-tabpanel-${index}`,
    };
}

export const SimpleTabs = ({ tabsLabels, tabsContent, defaultTab, getTab, tab, small }) => {
    const tabStyle = tabsStyles();
    const [value, setValue] = React.useState(defaultTab);
    const history = useHistory();
    const info = history?.location?.state;

    useEffect(() => {
        if (info?.activeTab) {
            setValue(info?.activeTab);
        }
    }, [info]);

    const handleChange = (newValue) => {
        setValue(newValue);
        const anotherInfo = {
            ...info,
            activeTab: newValue,
        };
        delete anotherInfo.sortType;
        delete anotherInfo.orderType;
        delete anotherInfo.type;
        delete anotherInfo.status;
        if(getTab){
            getTab(newValue);
        }else {
            SaveParams(history, { ...anotherInfo });
        }
    };

    return (
        <div className={tabStyle.root}>
            <AppBar className={tabStyle.tabHeader} position="static">
                <Tabs
                    // style={{width: '400px'}}
                    value={tab ? tab : value}
                    indicatorColor="primary"
                    className={tabStyle.collor}
                    aria-label="scrollable force tabs example"
                    scrollButtons="on"
                    variant="scrollable"
                >
                    {tabsLabels &&
                        tabsLabels.map((tabLabel, index) => {
                            if (tabLabel) {
                                return (
                                    <Tab
                                        key={index}
                                        onClick={() => {
                                            handleChange(tabLabel?.label);
                                        }}
                                        className={tabStyle.tabLabel}
                                        label={tabLabel?.label}
                                        value={tabLabel?.label}
                                        {...a11yProps(tabLabel?.label)}
                                    />
                                );
                            }
                        })}
                </Tabs>
            </AppBar>
            {tabsContent &&
                tabsContent.map((tabContent, index) => {
                    if (tabContent && tabContent?.tab === (tab ? tab : value)) {
                        return (
                            <div key={index}>
                                <TabPanel
                                    index={tabContent?.tab}
                                    boxClassName={small ? tabStyle?.smallBoxStyle : tabStyle?.boxStyle}
                                    value={tabContent?.tab}
                                >
                                    {tabContent?.tabComponent}
                                </TabPanel>
                            </div>
                        );
                    }
                })}
        </div>
    );
};
