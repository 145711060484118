import React, { useEffect, useState } from 'react';

export const IsImageLoaded = (imgSrc) => {
    const [imageLoaded, setImageLoaded] = useState(false);

    useEffect(() => {
        if (imgSrc){
            const img = new Image();
            img.src = imgSrc;
            img.onload = () => setImageLoaded(true)
        }

    }, [imgSrc]);

    return imageLoaded;
};

