import React from 'react';
import Box from '@material-ui/core/Box';
import { CardHeader, cardStyle, DateRow, getCardBorderTop, NoYet, TextRow, StatusRow } from 'components';
import { Images, PermissionList, RolePermission, useModal } from 'utils';
import { serviceSingleStyles } from './styles';
import { ClientDetails } from '../../clientModals/clientDetails';
import { useSelector } from 'react-redux';
import { InsuredInfo } from '../../clientModals';
import { CardItem } from '../../../../components/card/cardItem';

export const ClientGeneralInfo = ({}) => {
    const cardClasses = cardStyle();
    const classes = serviceSingleStyles();
    const borderTop = getCardBorderTop('General Info');
    const { open } = useModal();
    const data = useSelector((state) => state.client.clientItemInfo);
    const phone = data?.insuredInfo?.phoneNumber ? data?.insuredInfo?.phoneNumber : '';

    return (

        <div
            className={cardClasses.card}
            style={{ width: '32.5%', maxWidth: '510px' || 210, height: 'auto', minHeight: '430px' }}
        >
            <>
                <div className={cardClasses.headerBorder} style={{ borderTop }} />
                <div className={classes.spaceBetween}>
                    <CardHeader
                        color={'#347AF0'}
                        title={'General Info'}
                        icon={Images.generalInfoIcon}
                    />
                    {RolePermission([PermissionList.CLIENT_CREATE?.code]) &&
                        <button onClick={() => open(<ClientDetails info={data} />)} className={classes.addButton}>
                            <img src={Images.edit} alt="icon" />
                        </button>
                    }
                </div>
            </>
            <div>
                <CardItem
                    title={'Rendering Provider'}
                    value={data?.renderingProviderName ? data.renderingProviderName : 'Not Set'}
                />
                <div className={classes.statusWrapper}>
                    <Box className={cardClasses.cardItem} style={{ padding: 0, marginBottom: 0 }}>
                        <div className={cardClasses.cardItemContainerStyle} style={{ alignItems: 'center' }}>
                            <p className={classes.rowTitle} style={{ whiteSpace: 'nowrap', marginRight: '6px' }}>
                                Status:
                            </p>
                            <StatusRow status={data?.status} />
                        </div>
                    </Box>
                    {data?.statusDate &&
                        <Box className={cardClasses.cardItem} style={{ padding: 0, marginBottom: 0 }}>
                            <div className={cardClasses.cardItemContainerStyle}>
                                <p className={classes.rowTitle} style={{ whiteSpace: 'nowrap' }}>
                                    Status Date:
                                </p>
                                <span style={{ width: '100%', marginTop: '2px' }} className={cardClasses.cardValue}>
                                <DateRow date={data?.statusDate} />
                            </span>
                            </div>
                        </Box>
                    }
                    {data?.statusReason &&
                        <Box className={cardClasses.cardItem} style={{ padding: 0, marginBottom: 0 }}>
                            <div className={cardClasses.cardItemContainerStyle}>
                                <p className={classes.rowTitle} style={{ whiteSpace: 'nowrap' }}>
                                    Reason:
                                </p>
                                <span style={{ width: '100%', marginTop: '2px' }} className={cardClasses.cardValue}>
                                <TextRow name={data?.statusReason} />
                            </span>
                            </div>
                        </Box>
                    }
                </div>
            </div>
            <div style={{ marginTop: '24px' }}>
                <div className={classes.spaceBetween} style={{ alignItems: 'center' }}>
                    <p className={classes.insuredTitle}>Insured Info</p>
                    {RolePermission([PermissionList.CLIENT_CREATE?.code]) &&
                        <button onClick={() => open(<InsuredInfo client={data} />)} className={classes.addButton}>
                            <img src={data?.insuredInfo?.firstName ? Images.edit : Images?.addIcon} alt="icon" />
                        </button>
                    }
                </div>
                {data?.insuredInfo?.firstName ?
                    <div style={{ marginTop: '16px' }}>
                        <CardItem
                            title={'Full Name'}
                            value={`${data?.insuredInfo?.firstName} ${data?.insuredInfo?.lastName} ${data?.insuredInfo?.middleName ? data?.insuredInfo?.middleName : ''}`}
                        />
                        <CardItem
                            title={'Phone Number'}
                            value={
                                data?.insuredInfo?.phoneNumber ? '(' + phone.substr(0, 3) + ') ' + phone.substr(3, 3) + '-' + phone.substr(6, 6) : ''
                            }
                        />
                        {data?.insuredInfo?.address?.formattedAddress &&
                            <CardItem
                                title={'Address'}
                                value={
                                    `${data?.insuredInfo?.address?.street ? data?.insuredInfo?.address?.street : ''}${data?.insuredInfo?.address?.unitNumber ? `, Unit ${data?.insuredInfo?.address?.unitNumber},` : ''}
                                     ${data?.insuredInfo?.address?.city ? data?.insuredInfo?.address?.city : ''}
                                     ${data?.insuredInfo?.address?.state ? data?.insuredInfo?.address?.state : ''}
                                     ${data?.insuredInfo?.address?.zip ? data?.insuredInfo?.address?.zip : ''}`
                                }
                            />
                        }
                    </div>
                    :
                    <NoYet text="No Insured Yet" />
                }
            </div>
        </div>
    )
        ;
};