import {
    DELETE_APPOINTMENT_SUCCESS,
    EDIT_APPOINTMENT_POSITION, GET_APPOINTMENT_BY_FILTERS_SUCCESS,
    GET_APPOINTMENT_BY_ID_SUCCESS, GET_APPOINTMENT_INFORMATION, GET_APPOINTMENT_INFORMATION_SUCCESS,
    GET_APPOINTMENT_SUCCESS, GET_APPT_DOCUMENT_SUCCESS,
    GET_APPT_STAFFS_SUCCESS,
    GET_CLIENT_STAFFS_SUCCESS, REMOVE_APPOINTMENT_BY_ID,
    REMOVE_CLIENT_STAFFS,
    SAVE_CURRENT_LOCATION,
    SET_APPOINTMENT_STATUS_SUCCESS,
} from './appointment.type';
import { deleteAppt, handleReorderCategory } from './index';

const initialState = {
    appointments: [],
    appointmentById: {},
    geoPos: null,
    clientStaffs: [],
    apptStaffs: [],
    apptDocuments: null,
    apptAccounting: null,
    appts: [],
};

export const appointmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case  GET_APPOINTMENT_SUCCESS :
            return {
                ...state,
                appointments: action.payload,
            };

        case  DELETE_APPOINTMENT_SUCCESS :
            return {
                ...state,
                appointments: deleteAppt(state.appointments, action.payload),
            };

        case  SET_APPOINTMENT_STATUS_SUCCESS :
            return {
                ...state,
                appointments: state.appointments.map((post) =>

                    post?.id === action.payload?.id ? { ...action.payload } : post),
            };

        case  GET_APPOINTMENT_BY_ID_SUCCESS :
            return {
                ...state,
                appointmentById: action.payload,
            };

        case  REMOVE_APPOINTMENT_BY_ID :
            return {
                ...state,
                appointmentById: {},
            };

        case  GET_APPOINTMENT_INFORMATION_SUCCESS :
            return {
                ...state,
                apptAccounting: action.payload,
            };

        case  GET_APPOINTMENT_INFORMATION :
            return {
                ...state,
                apptAccounting: null,
            };

        case GET_APPOINTMENT_BY_FILTERS_SUCCESS:
            return {
                ...state,
                appts: action.payload,
            };

        case EDIT_APPOINTMENT_POSITION :
            return {
                ...state,
                appointments: handleReorderCategory(action.payload.body, action.payload.id, state.appointments),
            };

        case SAVE_CURRENT_LOCATION :
            return {
                ...state,
                geoPos: action.payload,
            };

        case GET_CLIENT_STAFFS_SUCCESS :
            return {
                ...state,
                clientStaffs: action.payload,
            };

        case REMOVE_CLIENT_STAFFS :
            return {
                ...state,
                clientStaffs: [],
            };

        case GET_APPT_STAFFS_SUCCESS :
            return {
                ...state,
                apptStaffs: action.payload,
            };

        case GET_APPT_DOCUMENT_SUCCESS:
            return {
                ...state,
                apptDocuments: action.payload,
            };

        default:
            return state;
    }
};