import React, { useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
    ForgotModal,
    LoginModal,
    ResetModal,
} from 'fragments';
import { landingImages, Images } from 'assets';
import { IsImageLoaded } from 'utils';

export function LoginPage() {
    const history = useHistory();
    const { search, pathname } = useLocation();
    const searchParams = new URLSearchParams(search);
    const resetToken = searchParams.get('resetToken');
    const registerToken = searchParams.get('token');
    const userEmail = searchParams.get('email');
    const headerRef = useRef(null);
    const imageLoaded = IsImageLoaded(Images.authImage)

    const renderScreen = () => {
        if (pathname === '/forgot') {
            return <ForgotModal />;

        } else {
            if (registerToken || userEmail || resetToken) {
                return <ResetModal />;
            } else {
                return <LoginModal />;
            }
        }
    };

    return (
        <div className='auth-page-wrapper open-sans'>
            <div className='auth-page_left-side'>
                <div className='auth-page_left-side_header' ref={headerRef}>
                    <landingImages.BlackLogo
                        className='auth-page_left-side_header-svg'
                        onClick={() => history.push('/')}
                    />
                </div>

                <div className='auth-page_left-side_content'>
                    {renderScreen()}
                </div>
            </div>
            <div className='auth-page_right-side'>
                <img
                    src={Images.authImageSmall}
                    alt='Auth Image'
                    // style={{
                    //     opacity: !imageLoaded ? 1 : 0,
                    //     visibility: !imageLoaded ? 'visible' : 'hidden'
                    // }}
                />
                <img
                    src={Images.authImage}
                    alt='Auth Image'
                    style={{
                        opacity: imageLoaded ? 1 : 0,
                        transition: "opacity 0.5s ease-in-out",
                        visibility: imageLoaded ? 'visible' : 'hidden'
                    }}
                />
            </div>
        </div>
    );
}
