import {
    GET_ADMIN_BY_ID,
    GET_ADMIN_BY_ID_SUCCESS,
    GET_ADMINS_SUCCESS,
    GET_CREDENTIAL_SUCCESS,
    GET_EMPLOYMENT_SUCCESS,
    GET_PAY_CODE_SUCCESS,
    GET_STAFF_SERVICE_SUCCESS,
    GET_ALL_PAY_CODES_SUCCESS,
    GET_TIMESHEET_SUCCESS,
    GET_ALL_ADMINS_SUCCESS,
    GET_TIMESHEET_BY_ID_SUCCESS,
    CLEAR_ALL_PAY_CODES,
    GET_ALL_PAY_CODES_FAIL,
    GET_STAFF_DOCUMENT_SUCCESS,
    REMOVE_TIMESHEET_BY_ID,
    GET_FOUNDING_ALL_SERVICES_SUCCESS,
    GET_STAFF_FOR_APPT_SUCCESS,
    GET_BASIC_ADMINS_SUCCESS,
    GET_BASIC_ACTIVE_ADMINS_SUCCESS,
} from './admin.types';

const initialState = {
    adminsList: [],
    adminsAllList: [],
    adminInfoById: '',
    credential: [],
    employments: [],
    payCodes: [],
    allPaycodes: [],
    staffServices: [],
    timesheet: [],
    timesheetById: null,
    staffDocuments: null,
    allServices: [],
    apptStaffs: [],
    basicAdmins: [],
    basicActiveAdmins: [],
};

export const adminReducer = (state = initialState, action) => {
    switch (action.type) {

        /** Staff */
        case GET_ADMIN_BY_ID:
            return {
                ...state,
                adminInfoById: action.payload.adminId,
            };

        case GET_ADMINS_SUCCESS:
            return {
                ...state,
                adminsList: action.payload.staff,
            };
        case GET_ALL_ADMINS_SUCCESS:
            return {
                ...state,
                adminsAllList: action.payload,
            };

        case GET_ADMIN_BY_ID_SUCCESS:
            return {
                ...state,
                adminInfoById: action.payload,
            };

        /** Edit */

        /** Staff Credentials */

        case GET_CREDENTIAL_SUCCESS:
            return {
                ...state,
                credential: action.payload.credentials,
            };

        /** Edit */

        /** Staff Employment */

        case GET_EMPLOYMENT_SUCCESS:
            return {
                ...state,
                employments: action.payload.reverse(),
            };

        /** Edit */

        /** Staff PayCode */

        case GET_PAY_CODE_SUCCESS:
            return {
                ...state,
                payCodes: action.payload.reverse(),
            };

        case GET_ALL_PAY_CODES_SUCCESS:
            return {
                ...state,
                allPaycodes: action.payload,
            };

        case GET_ALL_PAY_CODES_FAIL:
            return {
                ...state,
                allPaycodes: [],
            };

        case CLEAR_ALL_PAY_CODES:
            return {
                ...state,
                allPaycodes: [],
            };

        /** Edit */

        /** Staff Service */

        case GET_STAFF_SERVICE_SUCCESS:
            return {
                ...state,
                staffServices: action.payload,
            };

        /** Edit */

        /** Staff Timesheet */

        case GET_TIMESHEET_SUCCESS:
            return {
                ...state,
                timesheet: action.payload,
            };

        case GET_TIMESHEET_BY_ID_SUCCESS:
            return {
                ...state,
                timesheetById: action.payload,
            };

        case REMOVE_TIMESHEET_BY_ID:
            return {
                ...state,
                timesheetById: null,
            };

        /** Edit */

        case GET_STAFF_DOCUMENT_SUCCESS:
            return {
                ...state,
                staffDocuments: action.payload,
            };

        case GET_FOUNDING_ALL_SERVICES_SUCCESS:
            return {
                ...state,
                allServices: action.payload,
            };

        case GET_STAFF_FOR_APPT_SUCCESS:
            return {
                ...state,
                apptStaffs: action.payload,
            };

        case GET_BASIC_ADMINS_SUCCESS:
            return {
                ...state,
                basicAdmins: action.payload,
            };

        case GET_BASIC_ACTIVE_ADMINS_SUCCESS:
            return {
                ...state,
                basicActiveAdmins: action.payload,
            };
        default:
            return state;
    }
};
