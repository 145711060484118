import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FileUploader } from 'react-drag-drop-files';
import { ErrorText, FindLoad, Images, renderClientName } from 'utils';
import { CreateChancel, SelectTypeAutocomplete } from 'components';
import { appointmentActions, UploadServices } from 'store';
import { UploadedSignature } from './uploadedSignature';
import { GuardianSelector } from './guardianSelector';

export const Uploader = ({ appt, provider }) => {
    const ACTION_TYPE = provider ? 'APPEND_SIGNATURE_TO_APPMT_PROVIDER' : 'APPEND_SIGNATURE_TO_APPMT';
    const dispatch = useDispatch();
    const [currentFile, setCurrentFile] = useState(null);
    const [uploadLoader, setUploadLoader] = useState(false);
    const [selectedGuardian, setSelectedGuardian] = useState('');
    const loader = FindLoad(ACTION_TYPE);
    const alreadyUploaded = currentFile?.file?._id;

    useEffect(() => {
        if (provider) {
            if (appt?.providerSignature?._id) {
                setCurrentFile({ file: appt.providerSignature });
            }
        } else {
            if (appt?.signature?._id) {
                setCurrentFile({ file: appt.signature });
            }
        }
    }, [appt]);

    const handleFileChange = async (e) => {
        const pos = e.type.slice(e.type.search('/'));
        setCurrentFile({ file: e, type: pos.substring(1) });
    };

    const handleAddFile = async () => {
        if (currentFile) {
            let formData = new FormData();
            formData.append('files', currentFile.file);
            try {
                setUploadLoader(true);
                const res = await UploadServices.UploadImageThumb(formData);
                const info = {
                    file: {
                        ...res?.data,
                        name: currentFile.file.name,
                    },
                };
                if(!provider) {
                    selectedGuardian ? info.signor = selectedGuardian : null;
                }
                dispatch(appointmentActions.appendSignatureToAppmt(appt.id, info, provider));
                setUploadLoader(false);
            } catch (e) {
                setUploadLoader(false);
            }
        }
    };

    const handleRemoveUploaded = () => {
        const signature = provider ?
            appt?.providerSignature?._id ? { file: appt.providerSignature } : null :
            appt?.signature?._id ? { file: appt.signature } : null;
        setCurrentFile(signature);
    };

    return (
        <div className="space-between-column">
            <div>
                <FileUploader handleChange={handleFileChange} name="file">
                    <div className="drag-drop-signature-component">
                        <div className="flex-align-center" style={{ gap: 8, flexDirection: 'column' }}>
                            <img src={Images.uploadCloudBlack} alt="icon" />
                            <label className="custom-file-upload">
                                <input
                                    onClick={(event) => (event.target.value = null)}
                                    onChange={handleFileChange}
                                    type="file"
                                    id="file"
                                />
                                <i className="fa fa-cloud-upload" />
                                <div className="upload">
                                    <p>Drag and drop <br /> (e.g., PNG or JPG max size 5MB.)</p>
                                </div>
                            </label>
                        </div>
                        <p className="upload-or"> OR </p>
                        <button className="signature-upload-btn">
                            Upload Signature
                        </button>
                    </div>
                </FileUploader>

                <UploadedSignature
                    handleRemoveUploaded={handleRemoveUploaded}
                    alreadyUploaded={alreadyUploaded}
                    currentFile={currentFile}
                />
            </div>
            {!provider && <GuardianSelector setSelectedGuardian={setSelectedGuardian} selectedGuardian={selectedGuardian}/>}
            {!alreadyUploaded && currentFile &&
                <div style={{ marginTop: 24 }}>
                    <CreateChancel
                        height={'38px'}
                        noChancel={true}
                        loader={uploadLoader || !!loader?.length}
                        create={'Submit'}
                        onCreate={handleAddFile}
                        buttonWidth="100px"
                        justifyContent={'flex-end'}
                    />
                </div>
            }
        </div>
    );
};