import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment/moment';
import {
    CreateChancel,
    Input,
    SelectAutocompleteMultiple,
    DateCalendarForm, MonthCalendarForm, ErrMessage,
} from 'components';
import { useModal, Images, DownloadFile, ErrorText } from 'utils';
import { authService } from '../../../../store/reports/reports.service';

export const GenerateReport = ({ info }) => {
    const { close } = useModal();
    const { handleSubmit, watch, control, setError, clearErrors, reset, setValue } = useForm();
    const { loadDownload, handleDownload } = DownloadFile();
    const [load, setLoad] = useState(false);
    const { basicClients, fundingSourceList, adminsList, globalPayCodes } = useSelector((state) => ({
        basicClients: state.client.basicClients,
        fundingSourceList: state.fundingSource.fundingSourceList,
        adminsList: state.admins.adminsList,
        globalPayCodes: state.payroll.payCodes,
    }));
    const [backError, setBackError] = useState('');

    useEffect(() => {
        setBackError('');
    }, []);

    const handleGenerate = (data) => {
        setLoad(true);

        const params = {
            ...data,
        };
        params?.clientStatuses ? params.clientStatuses = params?.clientStatuses?.map((i) => i?.id) : null;
        params?.clientIds ? params.clientIds = params?.clientIds?.map((i) => i?.id) : null;
        params?.staffs ? params.staffs = params?.staffs?.map((i) => i?.id) : null;
        params?.payers ? params.payers = params?.payers?.map((i) => i?.id) : null;
        params?.clients ? params.clients = params?.clients?.map((i) => i?.id) : null;

        if (info?.title === 'Signature Report' && params?.date) {
            params.date = moment.utc(data?.date).set('date', 2).format('YYYY-MM-DD');
            // params.date = moment.utc(data?.date).format('YYYY-MM-DD');
        }
        try {
            authService.getReportService(info?.url, params).then((res) => {
                handleDownload({
                    ...res?.data,
                    originalName: `${res?.data?.originalName}${res?.data?.format ? res?.data?.format : ''}`,
                });
                close();
            }).catch((e) => {
                setBackError(e?.data?.message);
            }).finally(() => {
                setLoad(false);
            });
        } catch (e) {
        }
    };


    const renderList = (type, item) => {
        switch (type) {
            case 'clientIds':
                return basicClients;
            case 'client':
                return basicClients;
            case 'clients':
                return basicClients;
            case 'payer':
                return fundingSourceList?.funders;
            case 'payers':
                return fundingSourceList?.funders;
            case 'fundingSource':
                return fundingSourceList?.funders;
            case 'staff':
                return adminsList?.staffs;
            case 'staffs':
                return adminsList?.staffs;
            case 'payCodeTypeId':
                return globalPayCodes;

            default:
                return item?.selectList;
        }
    };

    return (
        <div style={{ width: 440 }}>
            <p className="modal-header-title">{info?.title}</p>
            <form onSubmit={handleSubmit(handleGenerate)}>
                <div className="modal-body-wrapper">

                    {info?.list?.map((item, index) => (
                        <div key={index}>
                            {item?.type === 'text' ?
                                <Input
                                    name={item?.name}
                                    label={item?.label}
                                    variant="outlined"
                                    control={control}
                                    type={item?.inputType ? item?.inputType : 'text'}
                                />
                                :
                                item?.type === 'select' ?
                                    <SelectAutocompleteMultiple
                                        options={renderList(item?.name, item)}
                                        renderValue={(option) => item?.renderValue(option)}
                                        label={`${item?.label}${item?.required ? '*' : ''}`}
                                        name={item?.name}
                                        control={control}
                                        loadType={item?.loadType}
                                        rules={{
                                            required: {
                                                value: item?.required ? item?.required : false,
                                                message: `${item?.label} is required.`,
                                            },
                                        }}
                                    />
                                    :
                                    item?.type === 'selectMultiple' ?
                                        <SelectAutocompleteMultiple
                                            loadType={item?.loadType}
                                            renderValue={(option) => item?.renderValue(option)}
                                            options={renderList(item?.name, item)}
                                            label={item?.label}
                                            name={item?.name}
                                            control={control}
                                            selectedType="id"
                                            multiple={true}
                                        />
                                        :
                                        item?.type === 'dateRange' ?
                                            <div className="flex-align-start" style={{ gap: 16 }}>
                                                <DateCalendarForm
                                                    name={item?.startDate}
                                                    label={item?.startLabel}
                                                    max={watch(item?.endDate) ? moment.utc(watch(item?.endDate)).format('YYYY-MM-DD') : ''}
                                                    control={control}
                                                />
                                                <DateCalendarForm
                                                    name={item?.endDate}
                                                    label={item?.endLabel}
                                                    min={watch(item?.startDate) ? moment.utc(watch(item?.startDate)).format('YYYY-MM-DD') : ''}
                                                    control={control}
                                                />
                                            </div>
                                            :
                                            item?.type === 'date' ?
                                                <DateCalendarForm
                                                    name={item?.name}
                                                    label={item?.label}
                                                    control={control}
                                                />
                                                :
                                                item?.type === 'dos' ?
                                                    <div style={{ gap: 12 }} className="flex-align-center">
                                                        <DateCalendarForm
                                                            control={control}
                                                            name={item?.startDate}
                                                            label={`${item?.startLabel}${item?.required ? '*' : ''}`}
                                                            max={watch(item?.endDate) ? moment.utc(watch(item?.endDate)).format('YYYY-MM-DD') : ''}
                                                            rules={{
                                                                required: {
                                                                    value: item?.required,
                                                                    message: `${item?.startLabel} is required.`,
                                                                },
                                                            }}
                                                        />
                                                        <DateCalendarForm
                                                            control={control}
                                                            name={item?.endDate}
                                                            label={`${item?.endLabel}${item?.required ? '*' : ''}`}
                                                            min={watch(item?.startDate) ? moment.utc(watch(item?.startDate)).format('YYYY-MM-DD') : ''}
                                                            rules={{
                                                                required: {
                                                                    value: item?.required,
                                                                    message: `${item?.endLabel} is required.`,
                                                                },
                                                            }}
                                                        />
                                                        {/*<DateTypeSelector*/}
                                                        {/*    outLabel={item?.label}*/}
                                                        {/*    filters={watch()}*/}
                                                        {/*    type={'modalInput'}*/}
                                                        {/*    handleGetDates={(e) => {*/}
                                                        {/*        setValue(item?.startDate, e?.start);*/}
                                                        {/*        setValue(item?.endDate, e?.end);*/}
                                                        {/*    }}*/}
                                                        {/*    startName={item?.startDate}*/}
                                                        {/*    endName={item?.endDate}*/}
                                                        {/*    // rules={{*/}
                                                        {/*    //     required: item?.required ? 'Please select a type from the dropdown.' : false,*/}
                                                        {/*/>*/}
                                                    </div>
                                                    :

                                                    item?.type === 'monthDos' ?
                                                        <div style={{ gap: 12 }} className="flex-align-center">
                                                            <MonthCalendarForm
                                                                control={control}
                                                                name={item?.startDate}
                                                                label={`${item?.startLabel}${item?.required ? '*' : ''}`}
                                                                max={watch(item?.endDate) ? moment.utc(watch(item?.endDate)).format('YYYY-MM-DD') : ''}
                                                                rules={{
                                                                    required: {
                                                                        value: item?.required,
                                                                        message: `${item?.startLabel} is required.`,
                                                                    },
                                                                }}
                                                            />
                                                            <MonthCalendarForm
                                                                control={control}
                                                                name={item?.endDate}
                                                                label={`${item?.endLabel}${item?.required ? '*' : ''}`}
                                                                min={watch(item?.startDate) ? moment.utc(watch(item?.startDate)).format('YYYY-MM-DD') : ''}
                                                                rules={{
                                                                    required: {
                                                                        value: item?.required,
                                                                        message: `${item?.endLabel} is required.`,
                                                                    },
                                                                }}
                                                            />
                                                        </div>
                                                        :
                                                        item?.type === 'monthDate' ?
                                                            <div style={{ gap: 12 }} className="flex-align-center">
                                                                <Input
                                                                    name={item?.name}
                                                                    label={`${item?.startLabel}${item?.required ? '*' : ''}`}
                                                                    placeholder={'MM/YYYY'}
                                                                    variant="outlined"
                                                                    control={control}
                                                                    type={'month'}
                                                                    rules={{
                                                                        required: {
                                                                            value: item?.required,
                                                                            message: `${item?.startLabel} is required.`,
                                                                        },
                                                                    }}
                                                                />
                                                                {/*<MonthCalendarForm*/}
                                                                {/*    control={control}*/}
                                                                {/*    name={item?.name}*/}
                                                                {/*    label={`${item?.startLabel}${item?.required ? '*' : ''}`}*/}
                                                                {/*    rules={{*/}
                                                                {/*        required: {*/}
                                                                {/*            value: item?.required,*/}
                                                                {/*            message: `${item?.startLabel} date is required.`,*/}
                                                                {/*        },*/}
                                                                {/*    }}*/}
                                                                {/*/>*/}
                                                            </div>

                                                            :
                                                            <div />
                            }
                        </div>
                    ))}
                    <ErrMessage text={backError} />
                </div>



                <div>
                    <CreateChancel
                        image={Images.generateReportWhite}
                        type="submit"
                        loader={load || loadDownload}
                        create={'Generate'}
                        chancel={'Cancel'}
                        onClose={close}
                        buttonWidth="212px"
                        gap={'16px'}
                        cancelRight={'0'}
                    />
                </div>
            </form>
        </div>
    );
};