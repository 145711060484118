import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { FullTable } from 'components';
import { useModal } from 'utils';
import { adminActions } from 'store';
import { ACTION_TYPE, payCodeBody, payCodeHead } from './constants';
import { staffPaycodeNotYet } from '../constants';
import { PaycodeModal } from '../modals';

export const StaffEmpPaycodes = ({ }) => {
    const payCodes = useSelector((state) => state.admins.payCodes);
    const dispatch = useDispatch();
    const history = useHistory();
    const params = useParams();
    const info = history?.location?.state;
    const { open } = useModal();

    const getPayCodes = () => {
        const pushInfo = {
            ...info,
        };
        delete pushInfo.page;
        delete pushInfo.activeTab;
        delete pushInfo.limit;
        delete pushInfo.skip;
        dispatch(adminActions.getPayCode(params?.id, { ...pushInfo }));
    };

    useEffect(() => {
        if (info) {
            getPayCodes();
        }
    }, [info]);

    // const changeInputs = (e) => {
    //     setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    // };

    const handleInactivate = (item) => {

        open(<PaycodeModal item={item}/>)

        // open(<CustomDelete
        //     info="Inactivate Paycode"
        //     text="Are you sure you want to inactivate this paycode?"
        //     innerText="Inactivate"
        //     handleDel={() => dispatch(adminActions.terminatePayCode(item?.id, item?.staffId))}
        //     actionType={REQ_ACTION_TYPE}
        //     afterSuccess={getPayCodes}
        //     body={
        //         <div>
        //             <div className="deleting-client-info-box" style={{ alignItems: 'flex-start' }}>
        //                 <img style={{ marginTop: '3px' }} src={Images.inactivatePayCode} alt="icon" />
        //                 <p>{item?.payCodeTypeId?.name}</p>
        //             </div>
        //             {/*<DateTypeSelect*/}
        //             {/*    noError={true}*/}
        //             {/*    type={'modalInput'}*/}
        //             {/*    values={{ inactivationDate: inputs?.inactivationDate ? moment.utc(inputs?.inactivationDate).format('YYYY-MM-DD') : moment.utc().format('YYYY-MM-DD') }}*/}
        //             {/*    name="inactivationDate"*/}
        //             {/*    handleGetDates={changeInputs}*/}
        //             {/*    outLabel={'Inactivation Date'}*/}
        //             {/*    min={moment.utc(inputs?.startDate).format('YYYY-MM-DD')}*/}
        //             {/*/>*/}
        //             {/*<div className='tool-inf-box'>*/}
        //             {/*    <img src={Images.toolInfoIcon} alt={'icon'} />*/}
        //             {/*    <p>This date will be recorded in the paycode history.</p>*/}
        //             {/*</div>*/}
        //         </div>
        //     }
        // />);
    };

    return (
        <FullTable
            margin={'0'}
            head={payCodeHead}
            body={payCodeBody(handleInactivate)}
            loadingType={ACTION_TYPE}
            list={payCodes?.filter((i) => i?.payCodeTypeId)}
            notYet={staffPaycodeNotYet}
        />
    );
};
