export const renderClientName = (client) => {
    return client ?
        `${client?.code ? `(${client?.code})` : ''} ${client?.firstName ? `${client?.firstName},` : ''} ${client?.lastName ? client?.lastName : ''} ${client?.middleName ? `${client?.middleName?.slice(0, 1)}.` : ''} ` :
        'N/A';
};

export const renderStaffName = (staff) => {

    return staff ?
        `${staff?.firstName ? `${staff?.firstName},` : ''} ${staff?.lastName ? staff?.lastName : ''} ${staff?.middleName ? `${staff?.middleName?.slice(0, 1)}.` : ''} ` :
        'N/A';

};
