import React from 'react';
import { Colors, Images, manageType } from 'utils';
import { IconAndTitle, PhoneRow, TextRow, StatusRow } from 'components';
export const fsStatusEnums = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
}
export const fsHead = [
    { name: 'name', searchKey: 'NAME', title: 'Name' },
    {
        name: 'type', title: 'Type', disabled: true, width:'155px',
        filterList: [
            { name: 'All', id: 'All' },
            { name: 'Private', id: 'PRIVATE' },
            { name: 'Public', id: 'PUBLIC' },
            { name: 'School', id: 'SCHOOL' },
        ],
    },
    { name: 'address', searchKey: 'ADDRESS', rowName: 'formattedAddress', title: 'Address', custom: false },
    { name: 'email', searchKey: 'EMAIL', title: 'Email Address', width: '16%', custom: false },
    { name: 'phoneNumber', searchKey: 'PHONE', title: 'Phone Number',  width:'150px', custom: false },
    { name: 'status', title: 'Status',  filterTitle: 'Filter the Status',  width:'120px', disabled: true,
        defaultSelected: fsStatusEnums.ACTIVE,
        filterList: [
            { name: 'All', id: 'ALL' },
            { name: 'Active', id: fsStatusEnums.ACTIVE },
            { name: 'Inactive', id: fsStatusEnums.INACTIVE },
        ],
    },
];

export const fsBody = [
    { rowText: (item) => <IconAndTitle icon={Images.fundingSourceOutline} title={item?.name} textWidth={10}   /> },
    { rowText: (item) => manageType(item?.type) },
    {
        rowText: (item) => <TextRow name={item?.address?.formattedAddress ? item?.address?.formattedAddress : 'N/A'}  />,
    },
    { rowText: (item) => <TextRow name={item?.email} /> },
    { rowText: (item) => <PhoneRow phone={item?.phoneNumber ? item?.phoneNumber : ''} /> },
    { rowText: (item) => <StatusRow status={item?.status} /> },
];

export const ACTION_TYPE = 'GET_FUNDING_SOURCE';

export const FoundingStatuses = [
    { label: 'Active', value: 'ACTIVE', color: Colors.ThemeGreen },
    { label: 'Inactive', value: 'INACTIVE', color: Colors.TextSecondary },
];

export const fsNotYet = {
    title: 'No Funding Sources Added Yet',
    subTitle: 'Start adding your funding sources to ensure accurate billing and financial tracking.',
    image: Images.noFsYet,
}


