import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { renderClientName, SaveParams } from 'utils';
import { DateTypeSelector, DownloadCsv, SelectTypeAutocomplete, TabButtons } from 'components';
import { fundingSourceActions } from 'store';
import { claimEnums } from '../../../fragments/posting/claimPayments/claimPayments/constants';
import { SelectAutocompletePagination } from '../../../components/inputs/selectAutocompletePagination';

export const ClaimPaymentFilters = ({ renderParams }) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const pushInfo = history?.location?.state;
    const fundingSourceList = useSelector((state) => state.fundingSource.fundingSourceList);

    useEffect(() => {
        dispatch(fundingSourceActions.getFundingSource({sortType: 'name', orderType: 'ASC'}));
    }, []);

    const handleChange = (e) => {
        const info = {
            ...pushInfo,
        };
        info.skip = 0;
        info.limit = 50;
        info.page = 1;
        if (e.target.value === 'All' || !e.target.value) {
            delete info[e.target.name];
        } else {
            info[e.target.name] = e.target.value;
            if (e.target.name === 'client') {
                info['clientFull'] = e.target?.full;
            }
        }
        SaveParams(history, { ...info });
    };

    const handleChangeTab = (type) => {
        const info = {
            ...pushInfo,
            tabType: type,
        };
        info.skip = 0;
        info.limit = 50;
        info.page = 1;
        SaveParams(history, { ...info });
    };

    return (
        <div className="claim-payment-filters-wrapper">
            <TabButtons handleChangeTab={handleChangeTab} />
            <div className="filter-inputs-align-end">
                <SelectTypeAutocomplete
                    placeholder={pushInfo?.payer ? '' : 'All'}
                    name={'payer'}
                    handleSelect={handleChange}
                    defaultValue={pushInfo?.payer}
                    list={fundingSourceList?.funders ?
                        [{ id: 'All', name: 'All' }, ...fundingSourceList?.funders]
                        :
                        [{ id: 'All', name: 'All' }]
                    }
                    renderValue={(i) => i?.name}
                    uiType={'tableFilter'}
                    outLabel={'Funding Source'}
                    noError={true}
                />

                <SelectAutocompletePagination
                    outLabel={'Client'}
                    name={'client'}
                    handleSelect={handleChange}
                    placeholder={pushInfo?.clientFullName ? pushInfo?.clientFullName : 'All'}
                    defaultValue={pushInfo?.client}
                    renderValue={(i) => renderClientName(i)}
                    type={'client'}
                    uiType={'tableFilter'}
                    noError={true}
                    currentSelected={pushInfo?.clientFull ? [pushInfo?.clientFull] : []}
                />
                
                {/*<SelectTypeAutocomplete*/}
                {/*    placeholder={pushInfo?.client ? '' : 'All'}*/}
                {/*    name={'client'}*/}
                {/*    handleSelect={handleChange}*/}
                {/*    defaultValue={pushInfo?.client}*/}
                {/*    list={clientList ?*/}
                {/*        [{ id: 'All', firstName: 'All' }, ...clientList]*/}
                {/*        :*/}
                {/*        [{ id: 'All', firstName: 'All' }]*/}
                {/*    }*/}
                {/*    renderValue={(i) => renderClientName(i)}*/}
                {/*    uiType={'tableFilter'}*/}
                {/*    outLabel={'Client'}*/}
                {/*    noError={true}*/}
                {/*/>*/}
                <DateTypeSelector
                    startName={'from'}
                    endName={'to'}
                    outLabel={'DOS'}
                />
                <DownloadCsv
                    type={'claimPayment'}
                    fromModal={true}
                    status={pushInfo?.tabType ? pushInfo?.tabType : claimEnums.OPEN}
                />
            </div>
        </div>

    );
};