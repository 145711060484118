import React, { useEffect, useState } from 'react';
import { dropdownsStyle } from './styles';
import { useModal } from 'utils';
import { InactiveModal } from 'components';

export const UserInputsDropdown = ({ dropdownOptions = [], onPass, selected, actionType, type }) => {
    const classes = dropdownsStyle();
    const [dropdownIsShown, setDropdownIsShown] = useState(false);
    const [current, setCurrent] = useState({});
    const { open } = useModal();

    useEffect(() => {
        if (selected) {
            const currentSelected = dropdownOptions?.find((i) => i?.id === selected);
            setCurrent(currentSelected);
        }
    }, [selected]);

    function chooseOptionHandler(option) {
        if(option?.id === selected) return setDropdownIsShown(false)
        if (option?.message) {
            open(
                <InactiveModal
                    actionType={actionType}
                    handleSave={(item) => {
                        onPass && onPass({ ...item, status: option?.id });
                        setCurrent(option);
                        setDropdownIsShown(false);
                    }}
                    name={type}
                />,
            );
        } else {
            onPass && onPass({status:option?.id});
            setCurrent(option);
            setDropdownIsShown(false);
        }
    }

    return (
        <>
            {dropdownIsShown && (
                <div
                    className={classes.dropOverlayStyle}
                    onClick={() => setDropdownIsShown(false)}
                />
            )}
            <div className={`${classes.userDropStyle}`}>
                <div className={`dropdown-box ${dropdownIsShown ? 'focused' : ''}`}>
                    <div
                        className="show-dropdown-box"
                        onClick={() => setDropdownIsShown((prevState) => !prevState)}
                    >
                        <h6 className={`dropdown-selected ${classes.optionStyle} ${current?.id}`}>
                            {current ? current?.label : 'Not Set'}
                        </h6>
                        <i className={`${dropdownIsShown ? 'active' : ''}`} />
                    </div>
                    {dropdownIsShown && (
                        <ul className="dropdown-options">
                            {dropdownOptions?.map((option, index) => {
                                return (
                                    <li
                                        key={index}
                                        className={`${classes.optionStyle} ${option?.id}`}
                                        onClick={() => chooseOptionHandler(option)}
                                    >
                              <span
                                  className={`dropdown-option-title ${option?.id === selected?.id ? 'selected-title' : ''}`}>
                                 {option ? option?.label : 'Not Set'}
                              </span>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            </div>
        </>
    );
};
