import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FindLoad, FindSuccessItem, useModal } from 'utils';
import { modalsStyle } from './styles';
import { CreateChancel } from '../buttons';
import { DateCalendarForm, TextareaCustom } from '../form';
import moment from 'moment';

export const InactiveModal = ({ name, handleSave, actionType }) => {
    const classes = modalsStyle();
    const loader = FindLoad(actionType);
    const success = FindSuccessItem(actionType);
    const { close } = useModal();
    const { handleSubmit, control } = useForm({
        defaultValues:{
            date: moment.utc().format('YYYY-MM-DD'),
        }
    });

    useEffect(() => {
        if (success) {
            close();
        }
    }, [success]);


    const handleCreate = (data) => {
        handleSave(data);
    };

    return (

        <div style={{ width: 400 }}>
            <form onSubmit={handleSubmit(handleCreate)}>
                <h1 className="modal-header-title"> {`Confirm ${name} Status Change `} </h1>
                <p className={classes.inactiveModalInfo}>{`Please specify the date and reason for changing the ${name}'s status.`}</p>

                <DateCalendarForm
                    name="date"
                    label={'Date*'}
                    // max={watch('endDate') ? moment.utc().format('YYYY-MM-DD') : ''}
                    control={control}
                    rules={{ required: 'Date is required.' }}
                />
                <TextareaCustom
                    control={control}
                    name="statusReason"
                    label="Reason"
                    variant={'outlined'}
                    allowCharacter={true}
                    maxLength={100}
                />
                <CreateChancel
                    type="submit"
                    loader={!!loader.length}
                    create={'Save Changes'}
                    chancel={'Cancel'}
                    onClose={close}
                    buttonWidth="224px"
                />
            </form>
        </div>
    );
};
