import { useSelector } from 'react-redux';

export {
    Colors,
    Shadow,
    Backgrounds,
    useGlobalStyles,
    useGlobalTextStyles,
    useGlobalText,
    createInputsWrapper,
    AntSwitch,
    globalModals,
} from './globalStylesUi';
export { Images } from './images';
export {
    EmailValidator,
    PasswordValidator,
    DomainNameValidator,
    URLValidator,
    UrlRegex,
    OnlyNumber,
} from './validations';
export { superAdminRouters } from './routers';
export {
    PermissionsList,
    paginate,
    filterByFirstName,
    initAxiosInterceptors,
    activeInactive,
    ToastSuccess,
    ToastFail,
    FindSuccessItem,
    FindSuccess,
    FindLoad,
    FindError,
    ActiveInactiveStatus,
    ActiveInactiveStatusReverse,
    getPhoneErrorText,
    isNotEmpty,
    makeEnum,
    transformPermission,
    getValTillTenDig,
    getLimitedVal,
    manageStatus,
    getMenuTitle,
    makeCapitalize,
    useWidth,
    hooksForTable,
    ImgUploader,
    hooksForErrors,
    addHiddenClass,
    ssnActions,
    createCodeFromName,
    getSkipCount,
    useWeekInterval,
    manageType,
    textResizer,
    handleTooltip,
    FindErrorItem,
    getDisplayDependsOnWidth,
    SavePage,
    SendPageSave,
    SaveParams,
    PascalCase,
    manageStatusCircle,
    convertHours,
    RolePermission,
    PermissionList,
    multiConverter,
    convertToDateTime,
    DownloadFile,
    formatAMPM,
    filterFromTable,
    InactivityHandler,
    useScrollTop,
    formatMiles,
    TimeIsGreat,
    formatAMPMNeW,
    renderSize,
    getFileType,
    base64ToFile,
    newDateWithoutUtc,
    renderClientName,
    renderStaffName,
    IsImageLoaded,
} from './hooks';
export { ErrorText } from './errorTexts';
export { CountryList, states } from './jsons';
export { languages, languagesById } from './languages';
export {
    list,
    getDataForTable,
    getGeneratingDataForTable,
    getModalDataForTable,
} from './constants';
export {
    DrawerContext,
    DrawerContextProvider,
    AllModals,
    ModalProvider,
    useModal,
    SecondaryModalProvider,
    SecondaryModals,
    useSecondaryModal,
} from './contexts';

export {
    enumValues,
    FLAGGED_ENUMS,
    FLAGGED,
    UNFLAGGED,
    FLAGGED_VALUES,
    PAYROLL_FLAGGED_ENUMS,
    APPT_FLAGGED_ENUMS,
    APPT_ENUMS,
    agingEnumsSelector,
    agingEnums,
    clientStatuses,
    credentialTypesEnums,
    activeInactiveEnums,
    tagEnums,
    staffStatuses,
    staffStatusEnums,
    clientStatusEnums,
} from './enumValues';
export const token = localStorage.getItem('access-token');


export const User = () => {
    const reducerUser = useSelector((state) => state.auth.userInfo);
    const localUser = JSON.parse(localStorage.getItem('wellUserInfo'));

    const userInfo = reducerUser || localUser;
    return { userInfo };

};