import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddButton, DownloadCsv, FullTable } from 'components';
import { CreateStaff } from 'fragments';
import { adminActions, systemActions, tagActions } from 'store';
import { ACTION_TYPE, staffBody, staffDefaultStatuses, staffHead, staffNotYet } from './constants';
import { PermissionList, RolePermission, tagEnums, useModal } from 'utils';
import { wrapperStyle } from '../../components/wrapper/styles';

export const Staff = () => {
    const classes = wrapperStyle();
    const dispatch = useDispatch();
    const history = useHistory();
    const info = history?.location?.state;
    const adminsList = useSelector((state) => state.admins.adminsList);
    const globalDepartments = useSelector((state) => state.system.departments);
    const { open } = useModal();

    const renderParams = () => {
        const filteredInfo = {
            ...info,
        };
        filteredInfo.limit = 50;
        filteredInfo.skip = info?.skip ? info?.skip : 0;


        if (info?.department) {
            if(info?.department === 'N/A'){
                filteredInfo.noDepartment = true;
                delete filteredInfo.department;
                delete filteredInfo.departmentIds;
            }else {
                filteredInfo.departmentIds = info?.department;
                delete filteredInfo.department;
                delete filteredInfo.noDepartment;
            }
        } else {
            delete filteredInfo.department;
            delete filteredInfo.noDepartment;
        }

        if (!info?.statuses) {
            filteredInfo.statuses = staffDefaultStatuses;
        }else if(info?.statuses?.length){
            filteredInfo.statuses = info?.statuses;
        }else {
            delete filteredInfo.statuses;
        }

        // if (info?.status === 'ALL') {
        //     delete filteredInfo.status;
        // } else if (info?.status) {
        //     filteredInfo.status = info?.status;
        // }

        delete filteredInfo.page;
        delete filteredInfo.activeTab;
        delete filteredInfo.name;
        return filteredInfo;
    }

    const getStaffs = (load) => {
        dispatch(adminActions.getAdmins(renderParams(), load));
    };

    useEffect(() => {
        getStaffs();
    }, [info]);

    useEffect(() => {
        dispatch(systemActions.getDepartments());
        dispatch(tagActions.getTags({ module: tagEnums.STAFF }));
    }, [dispatch]);

    const handleNavigate = (id) => {
        if (RolePermission([PermissionList.STAFF_READ.code])) {
            history.push(`/staff/${id}`);
        }
    };

    return (
        <>
            <div className={classes.buttonsTabStyleEnd}>
                <div className={classes.alignEnd} />
                <div className="flex-align-center" style={{ gap: '24px' }}>
                    <DownloadCsv type={'staff'} />
                    {RolePermission([PermissionList.STAFF_CREATE.code]) && (
                        <div className={classes.addButton}>
                            <AddButton
                                text={'Add Staff Member'}
                                handleClick={() => open(<CreateStaff afterSuccess={getStaffs} />)}
                            />
                        </div>
                    )}
                </div>
            </div>
            <FullTable
                head={staffHead(globalDepartments)}
                body={staffBody(renderParams())}
                loadingType={ACTION_TYPE}
                list={adminsList?.staffs}
                listCount={adminsList?.count}
                handleClick={handleNavigate}
                notYet={staffNotYet}
            />
        </>
    );
};