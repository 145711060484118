import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RouterSwitcher } from './root/routerSwitcher';
import { Footer, TopBar } from './fragments';
import { Images, ToastFail, ToastSuccess } from 'utils';
import { httpRequestsOnErrorsActions, httpRequestsOnSuccessActions } from 'store';
import { getToken } from 'firebase/messaging';
import { messaging } from './firebase';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { toastStyles } from './components/toast/styles';
import { toast, ToastContainer } from 'react-toastify';
import { Toast } from './components';
// import { onMessage } from 'https://www.gstatic.com/firebasejs/9.0.0/firebase-messaging.js';
// import { fetchToken, onMessageListener } from './firebase';

function App() {
    const { httpOnError, httpOnSuccess } = useSelector((state) => ({
        httpOnError: state.httpOnError,
        httpOnSuccess: state.httpOnSuccess,
    }));
    const classes = toastStyles();
    const dispatch = useDispatch();
    const route = useLocation();
    const token = localStorage.getItem('access-token');
    const success = httpOnSuccess.length && httpOnSuccess[0].type;
    const error = httpOnError.length && httpOnError[0].type;
    const errorMessage = httpOnError.length && httpOnError[0].error;
    const toastSuccess = ToastSuccess(success);
    const toastFail = ToastFail(error, errorMessage);

    useEffect(() => {
        if (!!toastSuccess) {
            // if (!!success) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(success));
        }
    }, [toastSuccess]);

    // useEffect(() => {
    //     if (!!toastFail) {
    //         if (!!error) {
    // dispatch(httpRequestsOnErrorsActions.removeError(error));
    // }
    // }, [toastFail]);

    const checkBar = () => {
        return route?.pathname === '/' ||
            route?.pathname === '/features' ||
            route?.pathname === '/about-us' ||
            route?.pathname === '/contact-us' ||
            route?.pathname === '/help-center' ||
            route?.pathname === '/help-center/getting-started' ||
            route?.pathname === '/help-center/control-panel' ||
            route?.pathname === '/terms-and-conditions' ||
            route?.pathname === '/privacy-policy';
    };

    const dontShowFooter = () => {
        return route?.pathname === '/help-center';
    };

    const registerServiceWorkerAndRequestPermission = async () => {
        if ('serviceWorker' in navigator) {
            try {
                const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
                messaging.useServiceWorker(registration);
            } catch (error) {
            }
        }
    };

    const requestNotificationPermission = async () => {
        try {
            const token = await getToken(messaging, { vapidKey: process.env.REACT_APP_VAPED });
            if (token) {
                const deviceId = getDeviceId();
                axios.patch('/staff/pnToken', {
                    pnToken: token,
                    deviceOS: 'WEB',
                    deviceId: deviceId,
                }, { auth: true }).then((res) => {
                }).catch((err) => {
                });
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        requestNotificationPermission().then(r => {
        });
        registerServiceWorkerAndRequestPermission().then(r => {
        });

    }, []);

    // onMessage(messaging, (payload) => {
    //     dispatch(notificationActions.getUnRenderNotifications());
    // });

    const getDeviceId = () => {
        let deviceId = localStorage.getItem('deviceId');
        if (!deviceId) {
            deviceId = uuidv4();
            localStorage.setItem('deviceId', deviceId);
        }
        return deviceId;
    };

    useEffect(() => {
        if (httpOnSuccess?.length) {
            httpOnSuccess?.forEach((item) => {
                const text = ToastSuccess(item?.type);
                if (text) {
                    toast(
                        <div className={classes.toastWrapper}>
                            <img src={Images.successIcon} alt="success" />
                            <p className={classes.toastText}>{text}</p>
                        </div>,
                    );
                }
                dispatch(httpRequestsOnSuccessActions.removeSuccess(item?.type));
            });
        }
        // if (httpOnError?.length) {
        //     httpOnError?.forEach((item) => {
        //         const toastFail = ToastFail(item?.type, item?.error);
        //         if (toastFail) {
        //             toast(
        //                 <div className={classes.toastWrapper}>
        //                     <img src={Images.successIcon} alt="success" />
        //                     <p className={classes.toastText}>{toastFail}</p>
        //                 </div>,
        //             );
        //         }
        //         dispatch(httpRequestsOnErrorsActions.removeError(item?.type));
        //     });
        // }
    }, [httpOnSuccess]);

    return (
        <div>
            {checkBar() ?
                <div className="App">
                    {!token && <TopBar />}
                    <RouterSwitcher />
                    {!token && !dontShowFooter() && <Footer />}
                </div>
                :
                <RouterSwitcher />
            }

            <Toast
                type={toastSuccess ? 'success' : toastFail ? 'error' : ''}
                // text={toastSuccess ? toastSuccess : toastFail ? toastFail : ''}
                text={toastFail ? toastFail : ''}
                info={toastFail ? !!toastFail : ''}
                // info={toastSuccess ? !!toastSuccess : toastFail ? !!toastFail : ''}
            />

            {/*<ToastContainer*/}
            {/*    className={`success ${classes.defaultToast}`}*/}
            {/*    position="top-center"*/}
            {/*    autoClose={3500}*/}
            {/*    hideProgressBar*/}
            {/*    newestOnTop={false}*/}
            {/*    closeOnClick*/}
            {/*    rtl={false}*/}
            {/*    pauseOnFocusLoss*/}
            {/*    draggable={false}*/}
            {/*    pauseOnHover={false}*/}
            {/*/>*/}
        </div>
    );
}

export default App;
