import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment/moment';
import { CreateChancel, DateTypeSelect, SelectTypeAutocomplete } from 'components';
import { renderClientName, renderStaffName, useModal } from 'utils';
import { CsvService } from 'store';
import { FormControlLabel, Radio } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { SelectAutocompletePagination } from '../../inputs/selectAutocompletePagination';

const GENERAL = 'GENERAL';
const DETAIL = 'DETAIL';
export const DownloadPendingClaims = ({ type }) => {
    const history = useHistory();
    const info = history?.location?.state;
    const fundingSourceList = useSelector((state) => state.fundingSource.fundingSourceList);
    const [inputs, setInputs] = useState({});
    const [load, setLoad] = useState(false);
    const [error, setError] = useState(false);
    const [generateType, setGenerateType] = useState(GENERAL);
    const { close } = useModal();

    useEffect(() => {
        if (info && Object.keys(info).length) {
            const params = {
                from: info?.from,
                to: info?.to,
                client: info?.client,
                payer: info?.payer,
                staff: info?.staff,
            };

            setInputs(params);
        }
    }, [info]);

    const handleChange = (e) => {
        setInputs((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    const handleExport = async () => {
        setLoad(true);
        let response = '';

        const params = {
            ...inputs,
            type: generateType,
            status: type === 'billedClaims' ? 'OPEN' : 'PENDING',
        };

        inputs?.staff ? params.staff = inputs.staff : delete params.staff;
        inputs?.client ? params.client = inputs.client : delete params.client;

        try {
            response = await CsvService.pendingClaimCsv(params);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${generateType === GENERAL ? 'General' : 'Detail'}-${type}.csv`);
            document.body.appendChild(link);
            link.click();
            setLoad(false);
            close();
        } catch (e) {
            setLoad(false);
            close();
        }
    };

    const handleChangeType = (e) => {
        setGenerateType(e.target.value);
        setError(false);
    };


    return (
        <div className="download-payroll-csv-modal">
            <p className="download-modal-title">Download Billing CSV</p>
            <p className="download-modal-desc">
                Select filters for the billing data you wish to download. The fields will be pre-filled based on your
                current filters.
            </p>
            <div className="download-modal-body">

                <div className="download-1500form-modal-body flex-align-start"
                     style={{ gap: 16, width: '100%', margin: '32px 0' }}>
                    <div className={type === 'pdf' ? 'form-type-box-active' : 'form-type-box-passive'}
                         style={{ borderColor: error ? '#F07379' : '', width: '100%' }}
                    >
                        <FormControlLabel
                            onChange={handleChangeType}
                            name="generateType"
                            value={GENERAL}
                            label="General Report"
                            control={<Radio
                                style={{ color: generateType === GENERAL ? '#347AF0' : '#D0D5DD' }}
                                checked={generateType === GENERAL}
                            />}
                        />
                    </div>
                    <div className={type === 'tab' ? 'form-type-box-active' : 'form-type-box-passive'}
                         style={{ borderColor: error ? '#F07379' : '', width: '100%' }}
                    >
                        <FormControlLabel
                            onChange={handleChangeType}
                            name="generateType"
                            value={DETAIL}
                            label="Detail Report"
                            control={<Radio
                                style={{ color: generateType === DETAIL ? '#347AF0' : '#D0D5DD' }}
                                checked={generateType === DETAIL}
                            />}
                        />
                    </div>
                </div>

                {type === 'billedClaims' ?
                    <SelectAutocompletePagination
                        title={'Staff Member'}
                        name={'staff'}
                        handleSelect={handleChange}
                        defaultValue={info?.staff}
                        renderValue={(i) => renderStaffName(i)}
                        type={'staff'}
                        currentSelected={info?.staffFull && inputs?.staff !== '' ? [info?.staffFull] : []}
                    />
                    // <SelectTypeAutocomplete
                    //     title={'Staff'}
                    //     name={'staff'}
                    //     handleSelect={handleChange}
                    //     defaultValue={inputs?.staff}
                    //     list={adminsList?.staffs?.length ? adminsList?.staffs : []}
                    //     renderValue={(i) => renderStaffName(i)}
                    //     placeholder={'Select staff'}
                    // />
                    :
                    <>
                        <SelectTypeAutocomplete
                            title={'Funding Source'}
                            name={'payer'}
                            handleSelect={handleChange}
                            defaultValue={inputs?.payer}
                            list={fundingSourceList?.funders ? fundingSourceList?.funders : []}
                            renderValue={(i) => i?.name}
                            placeholder={'Select Funding Source'}
                        />
                        <SelectAutocompletePagination
                            title={'Client'}
                            name={'client'}
                            handleSelect={handleChange}
                            defaultValue={info?.client}
                            renderValue={(i) => renderClientName(i)}
                            type={'client'}
                            currentSelected={info?.clientFull && inputs?.client !== '' ? [info?.clientFull] : []}
                        />
                        {/*<SelectTypeAutocomplete*/}
                        {/*    title={'Client'}*/}
                        {/*    name={'client'}*/}
                        {/*    handleSelect={handleChange}*/}
                        {/*    defaultValue={inputs?.client}*/}
                        {/*    list={clientList?.length ? clientList : []}*/}
                        {/*    renderValue={(i) => renderClientName(i)}*/}
                        {/*    placeholder={'Select Client'}*/}
                        {/*/>*/}
                    </>
                }
                <div className="start-end-time-box">
                    <DateTypeSelect
                        type={'modalInput'}
                        name={'from'}
                        outLabel={'Start Date'}
                        handleGetDates={handleChange}
                        values={inputs}
                        max={inputs.to ? moment.utc(inputs.to).format('YYYY-MM-DD') : ''}
                    />
                    <DateTypeSelect
                        type={'modalInput'}
                        name={'to'}
                        outLabel={'End Date'}
                        handleGetDates={handleChange}
                        values={inputs}
                        min={inputs.from ? moment.utc(inputs.from).format('YYYY-MM-DD') : ''}
                    />
                </div>
            </div>

            <div>
                <CreateChancel
                    loader={load}
                    create={'Download CSV'}
                    chancel={'Cancel'}
                    onCreate={handleExport}
                    onClose={() => close()}
                    buttonWidth="300px"
                />
            </div>
        </div>
    );
};