import { makeStyles } from "@material-ui/core/styles";
import { Colors, Backgrounds } from "utils";

export const errMessageStyle = makeStyles(() => ({
   warningBox: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
      marginBottom: '16px',
   },
   warning: {
      display: 'flex',
      alignItems: 'flex-start',
      background: 'rgba(255, 163, 48, 0.1)',
      borderRadius: '4px',
      padding: '8px',
      width: '100%',
      textAlign: 'start',
      '& img': {
         marginRight: '8px',
      },
      '& p': {
         fontWeight: '400',
         fontSize: '16px',
         color: '#51566D',
         lineHeight: '24px',
      },
   },
   errMessageCenterPosition: {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      margin: "3px 0 5px",
   },

   errMessageStyleText: {
      fontFamily: 'Open Sans, sans-serif',
      fontSize: "12px",
      fontWeight: "400",
      color: "#FE7070",
      display: "block",
      width: "100%",
      padding: "4px 16px",
      minHeight: "20px",
      textAlign: "left",
   },

   DoneMessage: {
      width: "420px",
      height: "62px",
      background: Backgrounds.whiteModal,
      boxShadow: "0px 0px 12px #0052E01F",
      borderRadius: "6px",
      display: "flex",
      alignItems: "center",
      padding: "20px 16px",
      position: "fixed",
      bottom: "50px",
      right: "40%",
      left: "40%",

      "& p": {
         fontSize: "16px",
         lineHeight: "24px",
         color: "#252E48",
      },
   },

   nameEllipsis: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "140px",
      width: 'auto',
      "@media (min-width: 1919px)": {
         width: "180px",
      },
   },

   addressEllipsis: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "200px",
      "@media (min-width: 1919px)": {
         width: "250px",
      },
   },
   desc: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "180px",
      "@media (min-width: 1919px)": {
         width: "280px",
      },
   },
   responsive: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "200px",
      fontSize: "14px",
      fontWeight: 600,
      color: Colors.TextPrimary,
      "@media (min-width: 1919px)": {
         maxWidth: "250px",
      },
   },
   emailEllipsis: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "150px",
      "@media (min-width: 1919px)": {
         width: "200px",
      },
   },

   notYetText: {
      fontSize: "16px",
      lineHeight: "24px",
      color: Colors.TextLight,
      fontWeight: "bold",
   },

   notYetCenter: {
      width: "100%",
      display: "flex",
      margin: "16px",
      "@media (min-width: 1918px)": {
         margin: "24px",
      },
   },
}));
