import { v4 as uuidv4 } from 'uuid';
import { credentialTypesEnums } from 'utils';
import { renderStaffName } from './constants';

export const staffCredentialReport = () => {
    return {
        title: 'Staff Credentials Expiration Report',
        url: '/reports/staff/credential/expiration',
        list:
            [
                {
                    type: 'select',
                    name: 'staffStatus',
                    label: 'Staff Status',
                    selectList: [
                        { name: 'Active', id: 'ACTIVE' },
                        { name: 'Inactive', id: 'INACTIVE' },
                    ],
                    renderValue: (option) => option?.name,
                },
                { type: 'date', name: 'credentialExpirationDate', label: 'Credential Expiration Date' },
                {
                    type: 'select',
                    name: 'credentialType',
                    label: 'Credential Type',
                    selectList: credentialTypesEnums,
                    renderValue: (option) => option?.name,
                },
            ],
    };
};
export const staffPaycodeReport = () => {
    return {
        title: 'Staff Paycodes Snapshot',
        url: '/reports/staff/paycode',
        list:
            [
                {
                    type: 'select',
                    name: 'staff',
                    label: 'Staff',
                    loadType: 'GET_ADMINS',
                    renderValue: (option) => renderStaffName(option)
                },
                {
                    type: 'select',
                    name: 'staffStatus',
                    label: 'Staff Status',
                    selectList: [
                        { name: 'Active', id: 'ACTIVE' },
                        { name: 'Inactive', id: 'INACTIVE' },
                    ],
                    renderValue: (option) => option?.name,
                },
                // {
                //     type: 'select',
                //     name: 'payCodeStatus',
                //     label: 'Pay Code Status ',
                //     selectList: [
                //         { name: 'Active', id: 'ACTIVE' },
                //         { name: 'Inactive', id: 'INACTIVE' },
                //     ],
                //     renderValue: (option) => option?.name,
                // },
            ],
    };
};

export const staffDetailReport = () => {
    return {
        title: 'Staff Info Details',
        url: '/reports/staff/details',
        list:
            [
                {
                    type: 'selectMultiple',
                    name: 'staffs',
                    label: 'Staff',
                    loadType: 'GET_ADMINS',
                    renderValue: (option) => renderStaffName(option)
                },
                {
                    type: 'select',
                    name: 'status',
                    label: 'Staff Status',
                    selectList: [
                        { name: 'Active', id: 'ACTIVE' },
                        { name: 'Inactive', id: 'INACTIVE' },
                    ],
                    renderValue: (option) => option?.name,
                },
            ],
    };
};


export const staffReportEnums = [
    {
        id: uuidv4(),
        name: 'Staff Credentials Expiration Report',
        description: 'Staff credentials nearing expiration to ensure compliance.',
        generateInfo: staffCredentialReport(),
    },
    {
        id: uuidv4(),
        name: 'Staff Paycodes Snapshot',
        description: 'Provides detailed information about staff pay codes, including rates, status, and dates.',
        generateInfo: staffPaycodeReport(),
    },
{
        id: uuidv4(),
        name: 'Staff Info Details',
        description: 'Staff profiles with personal, contact, employment, and credential details.',
        generateInfo: staffDetailReport(),
    },

];