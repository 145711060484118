import React from 'react';
import { Card, cardStyle } from 'components';
import { Colors, Images, PermissionList, useModal } from 'utils';
import { getStaffEmploymentInfo, getStaffGeneralInfo, getStaffOtherDetails } from './constants';
import { EmploymentModal } from './modals';
import { CreateStaff, StaffDetails } from '../../createStaff';

export const StaffGeneral = ({ staffGeneral, handleOpenModal, pastEmployments }) => {
    const cardClasses = cardStyle();
    const activeEmployment = staffGeneral?.employment ?  {...staffGeneral?.employment, active:true} : null;
    const generalInfo = getStaffGeneralInfo(staffGeneral, cardClasses);
    const otherDetails = getStaffOtherDetails(staffGeneral);
    const employmentInfo = getStaffEmploymentInfo(activeEmployment, pastEmployments);
    const { open } = useModal();

    return (
        <div className="information-card-wrapper">
            <Card
                maxWidth={'510px'}
                width="32.5%"
                cardInfo={generalInfo}
                showHeader={true}
                title="General Info"
                color={Colors.BackgroundBlue}
                icon={Images.generalInfoIcon}
                isBig={true}
                edit={true}
                editAccess={[PermissionList.STAFF_CREATE?.code]}
                handleEdit={() => open(<CreateStaff staffGeneral={staffGeneral} />)}
                bodyEdit={true}
            />
            <Card
                maxWidth={'510px'}
                width="32.5%"
                cardInfo={otherDetails}
                showHeader={true}
                title="Other Details"
                color={Colors.ThemeRed}
                icon={Images.otherDetailsIcon}
                isBig={true}
                edit={true}
                editAccess={[PermissionList.STAFF_CREATE?.code]}
                handleEdit={() => open(<StaffDetails staffGeneral={staffGeneral} />)}
                bodyEdit={true}
            />
            <Card
                maxWidth={'510px'}
                width="32.5%"
                cardInfo={employmentInfo}
                showHeader={true}
                title="Employment"
                color={Colors.BackgroundMango}
                icon={Images.portfolio}
                isBig={true}
                edit={!!activeEmployment}
                addEmployment={
                    pastEmployments?.length ? true :
                        !!activeEmployment
                }
                editAccess={[PermissionList.STAFF_CREATE?.code]}
                handleShowHistory={() => handleOpenModal('pastEmployment')}
                active={activeEmployment}
                handleEdit={(e) => open(<EmploymentModal edit={e === 'createEmployment' ? null : activeEmployment} />)}
                bodyEdit={true}
            />
        </div>
    );
};
