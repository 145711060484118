import React from 'react';
import { errMessageStyle } from './styles';
import { Images } from 'utils';


export const Warning = ({ text, styles, boxStyle, textStyles }) => {
    const classes = errMessageStyle()

    return (
        <div className={classes.warningBox} style={{...boxStyle}}>
            <div className={classes.warning} style={{...styles}}>
                <img src={Images.warning} alt="icon" />
                <div>
                    <p style={{...textStyles}}>{text}</p>
                </div>
            </div>
        </div>
    )
}