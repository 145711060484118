/** Tags */
import { ADD_TAG_TO_MEMBER, CREATE_TAG, DELETE_TAG, EDIT_TAG, GET_TAGS, REMOVE_TAG_FROM_MEMBER } from './tag.types';


export const getTags = (params) => {
    return {
        type: GET_TAGS,
        payload: { params },
    };
};

export const createTag = (body, params) => {
    return {
        type: CREATE_TAG,
        payload: { body, params },
    };
};

export const editTag = (body, params, requestParams) => {
    return {
        type: EDIT_TAG,
        payload: { body, params, requestParams },
    };
};

export const deleteTag = (id, module, requestParams) => {
    return {
        type: DELETE_TAG,
        payload: { id, module, requestParams },
    };
};

/** End */

/** Tag for members */
export const addTagToMember = (id, tagId, requestParams, module) => {
    return {
        type: ADD_TAG_TO_MEMBER,
        payload: { id, tagId, requestParams, module },
    };
};

export const removeTagFromMember = (id, tagId, requestParams, module) => {
    return {
        type: REMOVE_TAG_FROM_MEMBER,
        payload: { id, tagId, requestParams, module },
    };
};

/** End */