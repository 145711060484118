import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import debounce from 'lodash.debounce';
import { inputsStyle } from './styles';
import { InputMinLoader } from './inputMiniLoader';
import { TextRow } from '../table';
import { useHistory } from 'react-router-dom';
import { ErrMessage } from '../messages';
import { ErrorText, useGlobalStyles } from '../../utils';

export const SelectAutocompletePagination = ({

                                                 title,
                                                 defaultValue,
                                                 name,
                                                 handleSelect,
                                                 style,
                                                 disabled,
                                                 error,
                                                 placeholder,
                                                 renderValue,
                                                 customClass,
                                                 uiType,
                                                 outLabel,
                                                 outStyle,
                                                 startIcon,
                                                 type,
                                                 defaultStatuses,
                                                 noError, typeError, currentSelected = [],
                                             }) => {
    const classes = inputsStyle();
    const globalStyles = useGlobalStyles();
    const [value, setValue] = useState(null);
    const [options, setOptions] = useState(currentSelected ? currentSelected : []);
    const [page, setPage] = useState(1);
    const [open, setOpen] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const ITEMS_PER_PAGE = 50;
    const history = useHistory();
    const info = history?.location?.state;

    const fetchOptions = useCallback(
        debounce(async (page, searchTerm, statuses, info) => {
            try {
                setLoading(true);
                const apiUrl =
                    type === 'staff' ? '/staff/basic/info' :
                        type === 'client' ? '/client/basic/info' :
                            type === 'fullStaffList ' ? '/staff' :
                                '';

                const params = {
                    skip: (page - 1) * ITEMS_PER_PAGE,
                    limit: ITEMS_PER_PAGE,
                    statuses: statuses,
                    searchValue: searchTerm || undefined,
                    searchKey: searchTerm ? type === 'staff' ? 'NAME' : 'CODE' : undefined,
                    sortType: type === 'staff' ? 'fullName' : 'code',
                    orderType: 'ASC',
                };

                if (!info || !info?.showActive || info?.showActive === false) {
                    params.statuses = defaultStatuses;
                } else if (info?.showActive === true) {
                    params.statuses = [];
                }

                const response = await axios.get(apiUrl, {
                    params, auth: true,
                });

                const data = response.data || [];
                setOptions((prevOptions) => (page === 1 ? [...data, ...currentSelected] : [...prevOptions, ...data, ...currentSelected]));
                setHasMore(data.length >= ITEMS_PER_PAGE); // Check if more items are available
            } catch (error) {
                console.error('Error fetching options:', error);
            } finally {
                setLoading(false);
            }
        }, 300),
        [type],
    );

    useEffect(() => {
        fetchOptions(page, searchTerm, defaultStatuses, info);
    }, [page, searchTerm, fetchOptions]);

    useEffect(() => {
        if (defaultValue && currentSelected?.length && !value) {
            const defaultOption = currentSelected?.find((i) => i.id === defaultValue || i._id === defaultValue);
            setValue(defaultOption);
        }
    }, [defaultValue, currentSelected, value]);

    const handleScroll = (event) => {
        const listboxNode = event.currentTarget;
        if (listboxNode.scrollTop + listboxNode.clientHeight >= listboxNode.scrollHeight - 50) {
            if (hasMore && !loading) {
                setLoading(true);
                setPage((prevPage) => prevPage + 1);
            }
        }
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
        handleSelect({
            target: {
                value: newValue?.id || '',
                fullName: newValue?.fullName,
                full: newValue,
                name,
            },
        });
    };

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(1);
        setOptions([]);
        setHasMore(true);
    };

    useEffect(() => {
        if (info?.showActive === undefined || info?.showActive === false) {
            fetchOptions(page, searchTerm, defaultStatuses, info);
        } else if (info?.showActive === true) {
            fetchOptions(page, searchTerm, [], info);
        }
    }, [info?.showActive]);


    return (
        <div className={style || ''}>
            {outLabel && <p className={classes.labelTitle} style={{ ...outStyle }}>{outLabel}</p>}
            <Autocomplete
                style={{ width: '100%' }}
                className={
                    uiType === 'tableFilter' ? classes.noChipLoads :
                        uiType ? 'autocomplete-main-wrapper' :
                            disabled ? classes.inputTextFieldDisabled :
                                customClass ? customClass :
                                    error === name ? globalStyles.inputTextFieldError :
                                        defaultValue ? globalStyles.inputTextFieldBlue :
                                            classes.inputTextField
                }
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => {
                    setOpen(false);
                    setSearchTerm('');
                    if (!options?.length) {
                        fetchOptions(1, '', defaultStatuses, info);
                    }
                }}
                loading={loading}
                value={value}
                options={options}
                getOptionLabel={(option) => renderValue(option) || ''}
                ListboxProps={{ onScroll: handleScroll }}
                disabled={disabled}
                onChange={handleChange}
                renderOption={(option) => (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', width: '100%' }}>
                        {option.img && <img src={option.img} alt="icon" />}
                        <TextRow name={renderValue(option) || ' '} textWidth={7} />
                    </div>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder={placeholder}
                        label={title}
                        variant="outlined"
                        onChange={handleInputChange}
                        InputProps={loading || startIcon ? {
                            ...params.InputProps,
                            endAdornment: loading && <InputMinLoader />,
                            startAdornment: startIcon && <img src={startIcon} alt="icon" />,
                        } : params.InputProps}
                        error={!!error}
                    />
                )}
            />
            {!noError &&
                <ErrMessage
                    text={error === name ? typeError ? typeError : ErrorText.field : ''}
                    style={{ position: 'relative' }}
                />
            }
        </div>
    );
};
