export const renderColor = (type) => {
    switch (type) {
        case '#EFE5FD':
            return {
                background: '#EFE5FD',
                color: '#6002EE',
            };
        case '#008B00':
            return {
                background: '#008B00',
                color: '#E8F5E9',
            };
        case '#FFF2DF':
            return {
                background: '#FFF2DF',
                color: '#EE6002',
            };
        case '#E3F2FD':
            return {
                background: '#E3F2FD',
                color: '#1565C0',
            };
        case '#E0F2F1':
            return {
                background: '#E0F2F1',
                color: '#00695C',
            };
        case '#FFF9C4':
            return {
                background: '#FFF9C4',
                color: '#FFA500',
            };
        case '#EFEBE9':
            return {
                background: '#EFEBE9',
                color: '#5D4037',
            };
        case '#F9FAFB':
            return {
                background: '#F9FAFB',
                color: '#344054',
            };
        case '#FFEEE8':
            return {
                background: '#FFEEE8',
                color: '#E64A19',
            };
        case '#FFE9FC':
            return {
                background: '#FFE9FC',
                color: '#B622B7',
            };
        case '#C5F8FF':
            return {
                background: '#C5F8FF',
                color: '#00719F',
            };
        case '#EDEEFF':
            return {
                background: '#EDEEFF',
                color: '#5258E4',
            };




        //     OLDS

        case '#F8F9FC':
            return {
                background: '#F8F9FC',
                color: '#363F72',
            };
        case '#F9F5FF':
            return {
                background: '#F9F5FF',
                color: '#6941C6',
            };
        case '#EFF8FF':
            return {
                background: '#EFF8FF',
                color: '#175CD3',
            };

        case '#FFF6ED':
            return {
                background: '#FFF6ED',
                color: '#C4320A',
            };
        case '#FFF1F3':
            return {
                background: '#FFF1F3',
                color: '#C01048',
            };
        case '#FDF2FA':
            return {
                background: '#FDF2FA',
                color: '#C11574',
            };
        case '#F4F3FF':
            return {
                background: '#F4F3FF',
                color: '#5925DC',
            };
        case '#CBEAFA':
            return {
                background: '#CBEAFA',
                color: '#0D5C9A',
            };
        case '#E4EDFF':
            return {
                background: '#E4EDFF',
                color: '#1D4C9D',
            };

        default:
            return {
                background: '#F9F5FF',
                color: '#6941C6',
            };
    }
};

export const tagColors = [
    // {background: '#F9F5FF', color: '#6941C6'},
    // {background: '#EFF8FF', color: '#175CD3'},
    // {background: '#F8F9FC', color: '#363F72'},
    // {background: '#FFF6ED', color: '#C4320A'},
    // {background: '#FFF1F3', color: '#C01048'},
    // {background: '#FDF2FA', color: '#C11574'},
    // {background: '#F4F3FF', color: '#5925DC'},
    // {background: '#CBEAFA', color: '#0D5C9A'},
    // {background: '#E4EDFF', color: '#1D4C9D'},



    {background: '#EFE5FD', color: '#6002EE'},
    {background: '#008B00', color: '#E8F5E9'},
    {background: '#FFF2DF', color: '#EE6002'},
    {background: '#E3F2FD', color: '#1565C0'},
    {background: '#E0F2F1', color: '#00695C'},
    {background: '#FFF9C4', color: '#FFA500'},
    {background: '#EFEBE9', color: '#5D4037'},
    {background: '#F9FAFB', color: '#344054'},
    {background: '#FFEEE8', color: '#E64A19'},
    {background: '#FFE9FC', color: '#B622B7'},
    {background: '#C5F8FF', color: '#00719F'},
    {background: '#EDEEFF', color: '#5258E4'},

];