import React from 'react';
import { FullTable } from 'components';
import { ReportsBody, ReportsHead, ReportsNotYet, } from './constants';
import { useModal } from 'utils';
import { GenerateReport, signatureReportEnums } from './core';

export const SignatureReport = ({}) => {
    const { open } = useModal();

    const openGenerate = (item) => {
        open(<GenerateReport info={item?.generateInfo}/>)
    };

    return (
        <div>
            <FullTable
                head={ReportsHead}
                body={ReportsBody(openGenerate)}
                list={signatureReportEnums}
                notYet={ReportsNotYet('Signature')}
                activeRowId={''}
                margin={'0'}
            />
        </div>
    );
};