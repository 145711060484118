import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from '@material-ui/core';
import { FindLoad, FindSuccessItem, Images } from 'utils';
import { CreateChancel, CustomizedSwitch } from 'components';
import { fundingSourceActions, httpRequestsOnSuccessActions } from 'store';
import { fundingSourceSingleStyles } from './styles';
import { modalsStyle } from '../../../../components/modal/styles';

const ACTION_TYPE = 'EDIT_FS_SOURCE_GROUPING';

export const BillingSettings = () => {
    const classes = fundingSourceSingleStyles();
    const fsSource = useSelector((state) => state.fundingSource.fundingSourceItem);
    const dispatch = useDispatch();
    const loader = FindLoad(ACTION_TYPE);
    const successMerging = FindSuccessItem(ACTION_TYPE);
    const [showEdit, setShowEdit] = useState(false);
    const checkEditState = showEdit;
    const disabled = !showEdit;
    const modalStyles = modalsStyle();
    const [groupOptions, setGroupOptions] = useState({
        byCPTCode: false,
        byPOS: false,
        mergeBilling: false,
    });

    useEffect(() => {
        if (fsSource?.id) {
            setGroupOptions({
                byCPTCode: fsSource?.billingSettings?.byCPTCode,
                byPOS: fsSource?.billingSettings?.byPOS,
                mergeBilling: fsSource?.billingSettings?.mergeBilling,
            });
        }
    }, [fsSource]);

    useEffect(() => {
        if (successMerging?.type) {
            dispatch(httpRequestsOnSuccessActions.removeSuccess(ACTION_TYPE));
            setShowEdit(false);
        }
    }, [successMerging]);

    const handleSubmit = () => {
        if (fsSource?.billingSettings?.byCPTCode !== groupOptions.byCPTCode ||
            fsSource?.billingSettings?.byPOS !== groupOptions.byPOS ||
            fsSource?.billingSettings?.mergeBilling !== groupOptions?.mergeBilling
        ) {
            dispatch(fundingSourceActions.editFsSourceGrouping(fsSource?.id, groupOptions));
        }
    };

    const handleCancel = () => {
        setShowEdit(false);
    };

    const handleCheck = (e) => {
        setGroupOptions((prevState) => ({ ...prevState, [e.target.name]: e.target.checked }));
    };

    return (
        <div className={classes.settingsBox}>

            <div className="space-between" style={{ height: 45, alignItems: 'flex-start' }}>
                <p className={classes.billingTitle} style={{ margin: 0 }}>Billing Setup</p>
                {!checkEditState &&
                    <button className="reset-btn-no-paddings" onClick={() => setShowEdit(true)}>
                        <img src={Images.edit} alt="edit" />
                    </button>
                }
            </div>

            <div className={classes.companyBodyBox}>

                <div className={classes.mergeAppointmentsBox}>
                    <CustomizedSwitch
                        disabled={disabled}
                        checked={groupOptions.mergeBilling}
                        name="mergeBilling"
                        handleClick={() => handleCheck({
                            target: {
                                name: 'mergeBilling',
                                checked: !groupOptions.mergeBilling,
                            },
                        })}
                    />
                    <div style={{ marginTop: '-5px' }}>
                        <p>Merge Appointments</p>
                        <span className={classes.billingInfoTest}>Combines multiple appointments into a single billable line on claims</span>
                    </div>
                </div>

                <div className={classes.claimGrouping}>
                    <p className={classes.billingTitle} style={{ fontWeight: 600, margin: '0 0 4px 0' }}>Claim Grouping
                        Options</p>
                    <p className={classes.billingInfoTest}>Select how claims should be grouped during generation.</p>
                    <div className={classes.groupingOptionsWrapper}>
                        <div className={classes.groupingOptionBox}>
                            <Checkbox
                                disabled={disabled}
                                checked={groupOptions.byPOS}
                                name="byPOS"
                                className={modalStyles.customCheckbox}
                                onChange={handleCheck}
                            />
                            <p>By Place of Service</p>
                        </div>
                        <div className={classes.groupingOptionBox}>
                            <Checkbox
                                disabled={disabled}
                                checked={groupOptions.byCPTCode}
                                name="byCPTCode"
                                className={modalStyles.customCheckbox}
                                onChange={handleCheck}
                            />
                            <p>By CPT Code</p>
                        </div>
                    </div>
                </div>
                <div className="flex-align-center" style={{ gap: 4 }}>
                    <img src={Images.toolInfoIcon} alt={'icon'} />
                    <p className={classes.toolText}>Your selected grouping options will be applied during claims
                        generation</p>
                </div>
            </div>

            <div style={{ marginTop: 24 }}>
                {checkEditState &&
                    <CreateChancel
                        loader={!!loader?.length}
                        create={'Save'}
                        chancel={'Cancel'}
                        onCreate={handleSubmit}
                        onClose={handleCancel}
                        buttonWidth="292px"
                    />
                }
            </div>
        </div>
    );
};