import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { adminActions, appointmentActions, clientActions, httpRequestsOnSuccessActions, systemActions } from 'store';
import { FindSuccessItem, FLAGGED_VALUES, SaveParams, useModal } from 'utils';
import { SimpleModal } from 'components';
import { Filters, scheduleStyle, Selectable, CreateEvent, CalendarPage } from './core';
import { ScheduleList } from './core/scheduleList';
import { getBasicAdmins } from '../../store/admin/admin.action';

const RENDER_APPOINTMENTS_LIST = 'RENDER_APPOINTMENTS_LIST';
const CREATE_APPT = 'CREATE_APPOINTMENT';

export const ScheduleFragment = ({}) => {
    const classes = scheduleStyle();
    const dispatch = useDispatch();
    const [openEvtModal, setOpen] = useState(false);
    const [date, setDate] = useState('');
    const [fullScreen, setFullScreen] = useState(false);
    const renderAppointments = FindSuccessItem(RENDER_APPOINTMENTS_LIST);
    const history = useHistory();
    const info = history?.location?.state;
    const createApptSuccess = FindSuccessItem(CREATE_APPT);
    const { close } = useModal();

    useEffect(() => {
        if (renderAppointments?.type === RENDER_APPOINTMENTS_LIST) {
            setOpen(false);
            close();
            getEvents('noLoad');
            dispatch(httpRequestsOnSuccessActions.removeSuccess(RENDER_APPOINTMENTS_LIST));
        }
        if (createApptSuccess?.type === CREATE_APPT) {
            setOpen(false);
            dispatch(httpRequestsOnSuccessActions.removeSuccess(CREATE_APPT));
        }
    }, [renderAppointments, createApptSuccess]);

    useEffect(() => {
        if (info?.screenType === 'fullScreen') {
            setFullScreen(true);
        }
        getEvents('load');
        const timer = setInterval(() => {
            getEvents('noLoad');
        }, 30000);
        return () => clearInterval(timer);
    }, [info]);

    // useEffect(() => {
    //     // dispatch(clientActions.getClients({ sortType: 'code', orderType: 'ASC' }));
    //     // dispatch(adminActions.getAdmins());
    //
    //
    //     dispatch(appointmentActions.getApptStaffs());
    //
    //
    //
    //     dispatch(clientActions.getBasicClients());
    //     dispatch(adminActions.getBasicAdmins());
    //     dispatch(systemActions.getPlaces());
    //
    //
    //     // const newFilters = {
    //     //     ...info,
    //     // };
    //     // SaveParams(history, { ...newFilters });
    // }, []);

    const renderParams = useMemo(() => {
        const moment = require('moment');
        const currentDate = moment().utc();
        const currentWeekStart = currentDate.clone().startOf('isoWeek'); // ISO week starts on Monday
        const currentWeekEnd = currentDate.clone().endOf('isoWeek');
        const startDate = moment(info?.firstDate ? info?.firstDate : currentWeekStart);

        const date1 = new Date(info?.firstDate);
        const date2 = new Date(info?.lastDate);
        const differenceInTime = date2 - date1;
        const differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));

        if (differenceInDays > 7) {
            startDate.date(1);
        }

        const filterInfo = {
            startDate: startDate.format('YYYY-MM-DD'),
        };
        if (info?.viewType === 'calendar') {
            delete filterInfo.skip;
            delete filterInfo.limit;
        } else {
            filterInfo.skip = info?.skip ? info?.skip : 0;
            filterInfo.limit = 50;
        }
        info?.searchKey ? (filterInfo.searchKey = info.searchKey) : delete filterInfo.searchKey;
        info?.searchValue ? (filterInfo.searchValue = info.searchValue) : delete filterInfo.searchValue;


        const endDate = moment(info?.lastDate ? info?.lastDate : currentWeekEnd);
        filterInfo.endDate = endDate.format('YYYY-MM-DD');

        if (info?.flag) {
            if (info?.flag === FLAGGED_VALUES.REMOVED_FROM_CLAIM) {
                filterInfo.removedFromClaimFlag = true;
            } else {
                filterInfo.incompleteUnitFlag = true;
            }
        } else {
            delete filterInfo.flag;
            delete filterInfo.removedFromClaimFlag;
            delete filterInfo.incompleteUnitFlag;
        }

        if (info?.sortType) {
            filterInfo.sortType = info.sortType;
            filterInfo.orderType = info.orderType === 'DESC' ? 'ASC' : 'DESC';
        }

        // if (info?.status) {
        //     filterInfo.status = info.status;
        // } else {
        //     delete filterInfo.status;
        // }

        info?.types?.length ?  filterInfo.types = info.types?.map((i) => i?.id) : delete filterInfo.types;
        info?.statuses?.length ?  filterInfo.statuses = info.statuses?.map((i) => i?.id) : delete filterInfo.statuses;
        info?.client ? filterInfo.client = info.client : delete filterInfo.client;
        info?.staff ? filterInfo.staff = info.staff : delete filterInfo.staff;

        return filterInfo;
    }, [info]);

    const getEvents = (load) => {
        dispatch(appointmentActions.getAppointment(renderParams, load));
    };

    const handleOpenClose = (date) => {
        if (date) {
            setDate(date);
        } else {
            setDate('');
        }
        setOpen((prevState) => !prevState);
    };

    const openCloseFullScreen = () => {
        const newFilters = {
            ...info,
        };
        !fullScreen ? newFilters.screenType = 'fullScreen' : delete newFilters.screenType;
        SaveParams(history, { ...newFilters });
        setFullScreen(!fullScreen);
    };

    return (
        <div className={classes.wrapper}>
            <div className={info?.viewType === 'calendar' ? 'calendar-view-box' : ''}>
                <div className={info?.viewType === 'calendar' ? 'calendar-filter-box' : ''}>
                    <Filters
                        handleOpen={handleOpenClose}
                        openCloseFullScreen={openCloseFullScreen}
                    />
                </div>
                <>
                    {info?.viewType === 'calendar' ? (
                        <Selectable handleOpenClose={handleOpenClose} />
                    ) : (
                        <ScheduleList renderParams={renderParams} />
                    )}
                </>
            </div>

            <SimpleModal
                handleOpenClose={handleOpenClose}
                openDefault={openEvtModal}
                content={
                    <CreateEvent
                        renderParams={renderParams}
                        date={date}
                        handleOpenClose={handleOpenClose}
                    />
                }
            />

            <SimpleModal
                handleOpenClose={openCloseFullScreen}
                openDefault={fullScreen}
                secondary={true}
                bigSize={true}
                content={
                    <CalendarPage
                        handleOpen={handleOpenClose}
                        handleOpenClose={handleOpenClose}
                        closeFullScreen={openCloseFullScreen}

                    />
                }
            />
        </div>
    );
};
