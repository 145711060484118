import {
   CREATE_CLIENT,
   CREATE_CLIENT_AUTHORIZATION,
   CREATE_CLIENT_AUTHORIZATION_SERV,
   CREATE_CLIENT_CONTACT,
   CREATE_CLIENT_ENROLLMENT,
   DELETE_CLIENT,
   DELETE_CLIENT_AUTHORIZATION,
   DELETE_CLIENT_AUTHORIZATION_SERV,
   DELETE_CLIENT_CONTACT,
   DELETE_CLIENT_ENROLLMENT,
   EDIT_CLIENT,
   EDIT_CLIENT_AUTHORIZATION,
   EDIT_CLIENT_AUTHORIZATION_SERV,
   EDIT_CLIENT_CONTACT,
   EDIT_CLIENT_ENROLLMENT,
   GET_CLIENT_AUTHORIZATION,
   GET_CLIENT_AUTHORIZATION_SERV,
   GET_CLIENT_BY_ID,
   GET_CLIENT_CONTACTS,
   GET_CLIENT_ENROLLMENT,
   GET_CLIENTS,
   GET_CLIENT_AUTHORIZATION_MOD_CHECK,
   DELETE_CLIENT_AUTHORIZATION_FILE,
   EDIT_CLIENT_AUTHORIZATION_FILE,
   CREATE_CLIENT_AUTHORIZATION_FILE,
   GET_CLIENT_AUTHORIZATION_FILE,
   ADD_FILES_TO_CLIENT_AUTH,
   REMOVE_FILES_FROM_CLIENT_AUTH,
   TERMINATE_CLIENT_ENROLLMENT,
   EDIT_FILE_NAME_OF_CLIENT_AUTH,
   CHANGE_ENROLLMENT_TYPE,
   UPLOAD_CLIENT_DOCUMENT,
   EDIT_CLIENT_DOCUMENT,
   GET_CLIENT_DOCUMENT,
   DELETE_CLIENT_DOCUMENT,
   ADD_CLIENT_ADDRESS,
   EDIT_CLIENT_ADDRESS,
   DELETE_CLIENT_ADDRESS,
   ASSIGN_STAFF,
   GET_ASSIGNED_STAFFS,
   REMOVE_STAFF,
   SET_CLIENT_STATUS,
   EDIT_CLIENT_STATUS,
   EDIT_ASSIGNED_STAFF,
   GET_BASIC_CLIENTS, GET_BASIC_ACTIVE_CLIENTS,
} from './client.types';

export const getClients = (data, load) => {
   return {
      type: GET_CLIENTS,
      payload: { data, load },
   };
};
export const getBasicClients = (params) => {
   return {
      type: GET_BASIC_CLIENTS,
      payload: { params },
   };
};
export const getBasicActiveClients = (params) => {
   return {
      type: GET_BASIC_ACTIVE_CLIENTS,
      payload: { params },
   };
};

export const createClient = (body) => {
   return {
      type: CREATE_CLIENT,
      payload: { body },
   };
};

export const deleteClient = (id) => {
   return {
      type: DELETE_CLIENT,
      payload: { id },
   };
};

export const editClient = (body, id) => {
   return {
      type: EDIT_CLIENT,
      payload: { body, id },
   };
};

export const editClientStatus = (id, status) => {
   return {
      type: EDIT_CLIENT_STATUS,
      payload: { id, status },
   };
};

export const getClientsById = (id) => {
   return {
      type: GET_CLIENT_BY_ID,
      payload: { id },
   };
};

export const getClientsContacts = (id, data) => {
   return {
      type: GET_CLIENT_CONTACTS,
      payload: { id, data },
   };
};

export const createClientContact = (body, id) => {
   return {
      type: CREATE_CLIENT_CONTACT,
      payload: { body, id },
   };
};

export const editClientContact = (body, id, paramsId) => {
   return {
      type: EDIT_CLIENT_CONTACT,
      payload: { body, id, paramsId },
   };
};

export const deleteClientContact = (id, paramsId) => {
   return {
      type: DELETE_CLIENT_CONTACT,
      payload: { id, paramsId },
   };
};

/** Client Enrollment */

export const getClientsEnrollment = (id) => {
   return {
      type: GET_CLIENT_ENROLLMENT,
      payload: { id },
   };
};

export const createClientEnrollment = (body, id, funderId) => {
   return {
      type: CREATE_CLIENT_ENROLLMENT,
      payload: { body, id, funderId },
   };
};

export const editClientEnrollment = (body, clientId, funderId, id) => {
   return {
      type: EDIT_CLIENT_ENROLLMENT,
      payload: { body, clientId, id, funderId },
   };
};

export const terminateClientEnrollment = (clientId, enrollmentId, body) => {
   return {
      type: TERMINATE_CLIENT_ENROLLMENT,
      payload: { clientId, enrollmentId, body },
   };
};

export const deleteClientEnrollment = (id, clientId) => {
   return {
      type: DELETE_CLIENT_ENROLLMENT,
      payload: { id, clientId },
   };
};

export const changeEnrollmentType = ( info ) => {
   return {
      type: CHANGE_ENROLLMENT_TYPE,
      payload: info ,
   };
};

/** End */

/** Client Authorization */

export const getClientsAuthorizations = (id, load) => {
   return {
      type: GET_CLIENT_AUTHORIZATION,
      payload: { id, load },
   };
};

export const createClientsAuthorizations = (body, id, funderId) => {
   return {
      type: CREATE_CLIENT_AUTHORIZATION,
      payload: { body, id, funderId },
   };
};

export const editClientsAuthorizations = (body, id, clientId) => {
   return {
      type: EDIT_CLIENT_AUTHORIZATION,
      payload: { body, id, clientId },
   };
};

export const deleteClientsAuthorization = (id, clientId) => {
   return {
      type: DELETE_CLIENT_AUTHORIZATION,
      payload: { id, clientId },
   };
};

export const getClientsAuthorizationsServ = (id, load) => {
   return {
      type: GET_CLIENT_AUTHORIZATION_SERV,
      payload: { id, load },
   };
};

/** Client Authorization File */

export const createClientsAuthorizationFile = (body, createInfo) => {
   return {
      type: CREATE_CLIENT_AUTHORIZATION_FILE,
      payload: { body, createInfo },
   };
};

export const editClientAuthorizationFile = (body, id, clientId) => {
   return {
      type: EDIT_CLIENT_AUTHORIZATION_FILE,
      payload: { body, id, clientId },
   };
};

export const deleteClientAuthorizationFile = (id, clientId) => {
   return {
      type: DELETE_CLIENT_AUTHORIZATION_FILE,
      payload: { id, clientId },
   };
};

export const getClientsAuthorizationFile = (id) => {
   return {
      type: GET_CLIENT_AUTHORIZATION_FILE,
      payload: { id },
   };
};

/** End */

// end

export const getClientsAuthorizationsServModifiersCheck = (body, id, funderId) => {
   return {
      type: GET_CLIENT_AUTHORIZATION_MOD_CHECK,
      payload: { body, id, funderId },
   };
};

export const createClientsAuthorizationsServ = (body, id, funderId) => {
   return {
      type: CREATE_CLIENT_AUTHORIZATION_SERV,
      payload: { body, id, funderId },
   };
};

export const editClientsAuthorizationsServ = (body, id, authID) => {
   return {
      type: EDIT_CLIENT_AUTHORIZATION_SERV,
      payload: { body, id, authID },
   };
};

export const deleteClientsAuthorizationServ = (id, authID, reason) => {
   return {
      type: DELETE_CLIENT_AUTHORIZATION_SERV,
      payload: { id, authID, reason },
   };
};

export const addFilesToClientAuth = (authId, files, clientId) => {
   return {
      type: ADD_FILES_TO_CLIENT_AUTH,
      payload: { authId, files, clientId },
   };
};

export const removeFilesFromClientAuth = (authId, docId) => {
   return {
      type: REMOVE_FILES_FROM_CLIENT_AUTH,
      payload: { authId, docId },
   };
};

export const editFileNameOfClientAuth = (authId, docId, fileName) => {
   return {
      type: EDIT_FILE_NAME_OF_CLIENT_AUTH,
      payload: { authId, docId, fileName },
   };
};

/** Client Documents */

export const uploadClientDocument = (file, id) => {
   return {
      type: UPLOAD_CLIENT_DOCUMENT,
      payload: {file, id}
   }
}

export const editClientDocument = (id, dockId, file) => {
   return {
      type: EDIT_CLIENT_DOCUMENT,
      payload: {id, dockId, file}
   }
}

export const getClientDocument = (id) => {
   return {
      type: GET_CLIENT_DOCUMENT,
      payload: {id}
   }
}

export const deleteClientDocument = (clientId, id) => {
   return {
      type: DELETE_CLIENT_DOCUMENT,
      payload: {clientId, id}
   }
}

/** End */

/** Client Address */
export const addAddress = (id, body) => {
   return {
      type: ADD_CLIENT_ADDRESS,
      payload: {id, body}
   }
}

export const editAddress = (id, addressId, body) => {
   return {
      type: EDIT_CLIENT_ADDRESS,
      payload: {id, addressId, body}
   }
}

export const deleteAddress = (id, addressId) => {
   return {
      type: DELETE_CLIENT_ADDRESS,
      payload: {id, addressId}
   }
}
/** End */

/** Staff Assignment */
export const assignStaff = (body) => {
   return {
      type: ASSIGN_STAFF,
      payload: { body }
   }
}

export const editAssignedStaff = (id, body, clientId) => {
   return {
      type: EDIT_ASSIGNED_STAFF,
      payload: { id, body, clientId }
   }
}

export const getAssignedStaffs = (id) => {
   return {
      type: GET_ASSIGNED_STAFFS,
      payload: { id }
   }
}

export const removeClientStaff = ( clientId, staffId ) => {
   return {
      type: REMOVE_STAFF,
      payload: { clientId, staffId }
   }
}
/** End */