import { useEffect, useRef, useState } from 'react';
import * as React from 'react';
import { FindLoad, Images, PermissionList, renderStaffName } from 'utils';
import { ErrMessage, TextRow, InputMinLoader } from 'components';
import { Popper } from '@material-ui/core';
import { RolePermissionNoAdmin } from '../../../../utils/hooks/rolePermissions';
import { useSelector } from 'react-redux';

const GET_CLIENT_STAFFS = 'GET_CLIENT_STAFFS';
export const SelectStaff = ({
                                handleSelectStaff,
                                disabled,
                                typeError,
                                currentStaff,
                                clientStaffs,
                                handleRemoveStaff,
                            }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;
    const loader = FindLoad(GET_CLIENT_STAFFS);
    const [search, setSearch] = useState('');
    const staffRef = useRef(null);
    const [list, setList] = useState([]);
    const [sortedData, setSortedDate] = useState([]);
    const {  userInfo } = useSelector((state) => ({
        userInfo: state.auth.userInfo,
    }));

    const sortByChargeRatePriceDesc = (array) => {
        return array.sort((a, b) => b.chargeRatePrice - a.chargeRatePrice);
    };

    useEffect(() => {
        if (clientStaffs) {
            const sorted = sortByChargeRatePriceDesc(clientStaffs);
            setList(sorted);
            setSortedDate(sorted);
        }
    }, [clientStaffs]);

    const handleClick = (event) => {
        setSearch(null);
        staffRef.current.focus();
        if (!disabled) {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        }
    };

    const handleSelect = (item) => {
        handleSelectStaff(item);
        setAnchorEl(null);
        setSearch(null);
    };

    const removeStaff = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleRemoveStaff();
        setAnchorEl(null);
        setSearch(null);
    };

    const handleFilter = (e) => {
        setSearch(e.target.value);
        if (e.target.value) {
            const fullName = (el) => {
                return renderStaffName(el)
                    // `${el?.firstName} ${el?.lastName ? el?.lastName : ''}`;
            };
            const result = [];
            for (let i = 0; i < list.length; i++) {
                const matchingStaffs = list[i].staffs.filter(staff =>
                    fullName(staff) && fullName(staff).toString().toLowerCase().indexOf(e.target.value.toString().toLowerCase()) > -1,
                );
                if (matchingStaffs.length > 0) {
                    result.push({
                        chargeRateId: list[i].chargeRateId,
                        chargeRatePrice: list[i].chargeRatePrice,
                        staffs: matchingStaffs,
                    });
                }else{
                    result.push({
                        chargeRateId: list[i].chargeRateId,
                        chargeRatePrice: list[i].chargeRatePrice,
                        staffs: [],
                    });
                }
            }
            setList(result.length ? result : []);
        } else {
            setList(sortedData);
        }
    };

    const handleCloseAnchor = () => {
        setAnchorEl(null);
        setSearch(null);
        setList(sortedData);
    };

    const renderColorCircle = (index) => {
        if (list?.length >= 3) {
            if (index === 0) {
                return <div className="circle-box green" />;
            } else if (index === 1) {
                return <div className="circle-box yellow" />;
            } else {
                return <div className="circle-box red" />;
            }
        } else if (list?.length === 2) {
            if (index === 0) {
                return <div className="circle-box green" />;
            } else {
                return <div className="circle-box yellow" />;
            }
        } else {
            return <div className="circle-box green" />;
        }
    };

    // const renderShow = (item) => {
    //     if(RolePermissionNoAdmin([PermissionList.APPT_SELF_CREATE?.code])){
    //         if(item?.id === userInfo?.id){
    //             return true
    //         }else {
    //             return false
    //         }
    //     }else{
    //         return  true
    //     }
    // }

    return (
        <div className="staff-input-box">
            <div className={`staff-list-input`} onClick={handleClick}
                 style={disabled ? { borderColor: 'rgba(75, 92, 104, 0.50)' } :
                     typeError ? { borderColor: '#F07379' } :
                         {}}
            >
                <p className={anchorEl || currentStaff?.id ? 'staff-input-selected' : 'staff-input-not-selected'}
                   style={{
                       transition: 'all .3s',
                       color: disabled ? 'rgba(75, 92, 104, 0.50)' :
                           anchorEl || currentStaff?.id ? '#347AF0' :
                               'rgba(75, 92, 104 )',

                   }}
                >
                    Staff List*
                </p>
                <input
                    ref={staffRef}
                    disabled={disabled}
                    value={
                        search ? search :
                            currentStaff ? renderStaffName(currentStaff)
                                // `${currentStaff?.firstName} ${currentStaff?.lastName}`
                                : ''}
                    onChange={handleFilter}
                />

                {loader?.length ?
                    <InputMinLoader />
                    :
                    <div className="select-remove-icon">
                        {currentStaff && !disabled &&
                            <button
                                className="remove-selected-staff" onClick={(e) => removeStaff(e)}>
                                <img src={Images.remove} alt="icon" />
                            </button>
                        }
                        <img
                            className="input-rotate-icon"
                            style={anchorEl ? { transform: 'rotate(-180deg)' } : {}}
                            src={Images.dropdownArrowFilledBlue} alt="icon" />
                    </div>
                }
            </div>
            <ErrMessage
                styles={{lineHeight:'18px', marginBottom:typeError ? '6px' : 0}}
                text={typeError ? typeError : ''}
            />

            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                className="custom-select-paper-box"
                placement="bottom-start"
                style={{ position: 'absolute', zIndex: 2600 }}>
                <div>
                    {list?.map((item, index) => (
                        item?.staffs?.map((j, k) => (
                            <div key={k} className="select-item-box"
                                 onClick={() => handleSelect({ chargeRateId: item?.chargeRateId, ...j })}>
                                {renderColorCircle(index)}
                                <TextRow name={`${renderStaffName(j)} ${j?.employment ? `(${j.employment})` : ''}`} />
                            </div>
                        ))
                    ))}
                    {(!list?.length) ?
                        <div className="select-item-box">
                            <p>Not Set</p>
                        </div>
                        :
                        ''
                    }
                </div>
            </Popper>

            {open && <div className="beck-drop" onClick={handleCloseAnchor} />}
        </div>
    );
};
